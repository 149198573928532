import React, {useEffect, useState} from 'react'
import store from 'scripts/store';
import Net from 'scripts/net';
import classnames from 'classnames/bind';
import {Card, CardBody, CardSubTitle} from "../../../components/molecules/Card";
import CardLeft from "../../../components/molecules/Card/CardLeft";
import CardRight from "../../../components/molecules/Card/CardRight";
import ReactApexChart from 'react-apexcharts'

import {
    AiFillControl,
    BsFillPersonCheckFill,
    BsStopwatchFill,
    BsXSquareFill,
    FaUserAlt,
    FaUserCheck,
    FaUserFriends,
    FiEdit,
    GiVote,
    HiOutlineWifi,
    MdSettingsInputComposite,
    SiSamsung
} from "react-icons/all";
import {useHistory} from "react-router-dom";
import ReactTooltip from "react-tooltip";

const cx = classnames.bind();

const dateRegex = /T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$/g;
const startDate = new Date();
const timezoneOffset = startDate.getTimezoneOffset() * 60000; // for toISOString
const today = new Date(startDate - timezoneOffset);

const requestSearch = {currentPage: 0, rowPerPage: 0, searchText: ""};

export const Operator = (props) => {
    const history = useHistory();
    const user = props.userInfo;
    const siteCode = user.site && user.site.code;
    const customerCode = user.customer && user.customer.code;
    const [reservation, setReservation] = useState([]);
    const [users, setUsers] = useState(0);
    const [households, setHouseholds] = useState({});
    const [qnaState, setQnaState] = useState([]);
    const [voteState, setVoteState] = useState([]);
    const [etcCount, setEtcCount] = useState([]);
    const [voiceServiceCount, setVoiceServiceCount] = useState([]);
    const [homenetUsers, setHomenetUsers] = useState(0);
    const [homenetHouses, setHomenetHouses] = useState(0);
    const day = today.toISOString().replace(dateRegex, "");
    const [newUser, setNewUser] = useState([]);
    const [yesUser, setYesUser] = useState([]);

    const [chartOption, setChartOption] = useState({
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            stackType: '100%',
            toolbar: {
                show: true
            },
            zoom: {
                enabled: true
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    position: 'bottom',
                    offsetX: -10,
                    offsetY: 0
                }
            }
        }],
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 10
            },
        },
        xaxis: {
            categories: ['도서관', '골프장', '공유 주방', '영화관',
                '골프장', '골프장', '공용공간'
            ],
        },
        legend: {
            position: 'right',
            offsetY: 40
        },
        colors: ['#e6f2ff', '#006cff', '#9C27B0'],
        fill: {
            colors: ['#e6f2ff', '#006cff', ''],
            opacity: 1
        },
        dataLabels: {
            style: {
                colors: ['#090945', '#e2e5e8']
            }
        }

    },);
    const [chartSeries, setChartSeries] = useState(
        [{
            name: '잔여',
            data: [13, 23, 20, 8, 13, 27]
        }, {
            name: '예약',
            data: [44, 55, 41, 67, 22, 43]
        }]
    );

    useEffect(() => {
        store.openedPage = '대시 보드';
        init().then(() => {
            console.log("init complete");
        });
    }, [])

    const code = async () => {

        if (typeof window.CUSTOMER_LIST === "undefined") {
            await Net.getSitesListAsync(requestSearch)
                .then(response => {
                    let map = {};
                    let siteMap = {};
                    response.data.content.map = async function () {
                        let i = this.length;
                        while (i--) {
                            map[this[i]["code"]] = this[i]["customer"]["name"];
                            siteMap[this[i]["code"]] = {
                                name : this[i]["name"],
                                household : this[i]["numberOfHouseHold"]
                             }
                        }
                        return map;
                    }.call(response.data.content);
                    window.CUSTOMER_LIST = map;
                    window.SITE_LIST = siteMap;
                })
        }

        if (typeof window.COMMUNITY_CODE === "undefined") {
            const codeTypes = await Net.getCommunityCodeTypeListAsync({
                ...requestSearch,
                currentPage: "",
                rowPerPage: ""
            })
            let map = {};
            await codeTypes.data.content.map(async item => {
                map[item.code] = item.name;

                let subMap = {};
                const codes = await Net.getCommunityCodeAsync(item.code);
                await codes.data.forEach(item => {
                    subMap[item.code] = item.name;
                });

                window[item.code] = subMap;
                return map;
            })
            window.COMMUNITY_CODE = map;
        }
    }

    useEffect(()=>{
        console.log(etcCount);
    },[etcCount])


    const init = async () => {
        await code();
        await Net.getHomenetUserList({
            currentPage: 0,
            rowPerPage: 1000000000,
            searchCategory2: siteCode,
        }, response => {
            const des = new Date();
            const sta = new Date();
            const dayofMonth = des.getDate();
            sta.setDate(dayofMonth);
            des.setDate(dayofMonth-1);
            const yesterday = des.getFullYear() + '-' + ('0' + (Number(des.getMonth()) + 1)).slice(-2) + '-' + ('0' + des.getDate()).slice(-2) + ' 00:00';
            const oneMonth = des.getFullYear() + '-' + ('0' + (Number(sta.getMonth()))).slice(-2) + '-' + ('0' + des.getDate()).slice(-2) + ' 00:00';
            const today = sta.getFullYear() + '-' + ('0' + (Number(sta.getMonth()) + 1)).slice(-2) + '-' + ('0' + sta.getDate()).slice(-2)+ ' 23:59';
            const newUserArray = response.data.content.filter((item)=>{
                return item.createdDatetime >= yesterday && item.createdDatetime <= today;
            })
            console.log(yesterday, today, des)

            const tempArr = response.data.content.filter((item)=>{
                return item.createdDatetime >= oneMonth && item.createdDatetime <= today;
            })
            setNewUser(tempArr);
            setYesUser(newUserArray)


        });
        await Net.getUserList({
            currentPage: 0,
            rowPerPage: 1000000000,
            searchCategory2: siteCode,
        },response => {
            const placeUsers = response.data.content.filter((item)=>{
                return item.place && item.place.name !== ""
            })
            setUsers(placeUsers.length);
        })


        const tempEtcCount = await Net.getUserDeviceCountsBySiteAsync(customerCode, siteCode);
        if(tempEtcCount.data[0] === undefined){
            setEtcCount({
                "site": {
                    "code": "string",
                    "name": "string"
                },
                "dong": "string",
                "homenetUserCount": 0,
                "homenerUserDeviceCount": 0,
                "userAccountCount": 0,
                "userDeviceCount": 0,
                "sktUserCount": 0,
                "sktHouseHoldCount": 0,
                "sktUserDeviceCount": 0,
                "ktUserCount": 0,
                "ktHouseHoldCount": 0,
                "ktUserDeviceCount": 0,
                "lgUPlusUserCount": 0,
                "lgUPlusHouseHoldCount": 0,
                "lgUplusUserDeviceCount": 0,
                "lgUserCount": 0,
                "lgHouseHoldCount": 0,
                "lgUserDeviceCount": 0,
                "samsungUserCount": 0,
                "samsungHouseHoldCount": 0,
                "samsungUserDeviceCount": 0
            })
        } else {
            setEtcCount(tempEtcCount.data[0])
        }

        const voiceServiceHouseHoldCount = await Net.getHouseHoldVoiceServiceCountAsync(customerCode, siteCode);
        if(voiceServiceHouseHoldCount.data[0] === undefined){
            setVoiceServiceCount({
                "sktHouseHoldCount": 0,
                "ktHouseHoldCount": 0,
                "lgUPlusHouseHoldCount": 0,
            })
        } else {
            setVoiceServiceCount(voiceServiceHouseHoldCount.data[0])
        }

        await Net.getHouseholds(user, response => {
            setHouseholds(response);
        });
        await Net.getCommunityCode('RESERVATION_GROUP', response => {
            let codes = [];
            response.data.forEach(item => {
                codes.push(item.code);
            })

            Net.reservationCount(codes, siteCode, day)
                .then(response => {
                    setReservation(response);
                    const resArr = response.map(x => x.reservationCount)
                    const remainArr = response.map(x => x.remainCount)
                    const tempArr = response.map(x => window["RESERVATION_GROUP"] && window["RESERVATION_GROUP"][x.classification] + ' : ' + x.totalCount)
                    const cateArr = {categories: tempArr}
                    const temp = [{
                        name: '잔여',
                        data: remainArr,
                    }, {
                        name: '예약',
                        data: resArr
                    }];
                    setChartSeries(temp);
                    setChartOption({...chartOption, xaxis: cateArr})

                });
        });

        await Net.getVoteCount(siteCode, response => {
            setVoteState(response);
        })
        await Net.getQnaStatus(siteCode, response => {
            setQnaState(response);
        });
        await Net.getHomeNetUserCounts(siteCode, response => {
            setHomenetUsers(response);
        });
        await Net.getHomeNetHouseCounts(siteCode, response => {
            setHomenetHouses(response);
        });
    }
    const onClickUser = () => {
        history.push(`/member/user/list`);
    }
    const onClickReservation = () => {
        history.push(`/community/reservation/list`);
    }
    const onClickVote = () => {
        history.push(`/community/vote/list`);
    }
    const onClickQna = () => {
        history.push(`/community/qna/list`);
    }
    return (
        <div className={cx('container-fluid', 'bgC-operator', 'overFlowYscrollViewHidden', 'h-100')}>
            <div className="row">
                <div className="col-12 col-sm-6 col-md-8">
                    <h2 className={cx('mt-3', 'ml-2')}>세대 현황</h2>
                    <div className={cx('row', 'mt-4')}>
                        <div className="col"       >
                            <Card className="card_border">
                                <CardBody className="px-3 py-3-4">
                                    <div className="row">
                                        <CardLeft className="col-md-8 bgc-purple icon-box"><FaUserAlt size="24"
                                                                                                      color="white"/>
                                        </CardLeft>
                                        <CardRight className="col-md-8" title={"총 세대수"}
                                                   content={households.numberOfHouseHold}>
                                        </CardRight>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="col">
                            <Card className="card_border">
                                <CardBody className="px-3 py-4-5">
                                    <div className="row">
                                        <CardLeft className="col-md-8 bgc-purple icon-box"><FaUserCheck size="24"
                                                                                                        color="white"/></CardLeft>
                                        <CardRight className="col-md-8" title={"연동 세대수"} content={homenetHouses}/>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="col">
                            <Card className="card_border">

                                <CardBody className="px-3 py-4-5">
                                    <div className="row">
                                        <CardLeft className="col-md-8 bgc-purple icon-box"><AiFillControl size="24"
                                                                                                          color="white"/></CardLeft>
                                        <CardRight className="col-md-8" title={"연동 가입자"} content={homenetUsers}/>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="col">
                            <Card className="card_border">

                                <CardBody className="px-3 py-4-5">
                                    <div className="row">
                                        <CardLeft className="col-md-8 bgc-purple icon-box"><FaUserFriends size="24"
                                                                                                          color="white"/></CardLeft>
                                        <CardRight className="col-md-8" title={"가입자"} content={users}/>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <ReactTooltip
                            id="telecomLabel"
                            place="bottom"
                            class={"w-20"}
                        >음성 인식 스피커 및 가전 연동 서비스의 경우 아파트 단지별로 허용된 서비스가 다를 수 있습니다.</ReactTooltip>
                        <div className={cx('row', 'mt-4')}
                             data-for="telecomLabel" data-tip

                        >

                            <div className="col">
                                <Card className="card_border">

                                    <CardBody className="px-1 py-3">
                                        <div className="row">
                                            <CardLeft className="col-6 bgc-purple icon-box align-items-center"><HiOutlineWifi size="24"
                                                                                                              color="white"/></CardLeft>
                                            <CardSubTitle className="col-6" title={"KT 지니"} account={etcCount.ktUserCount} houseHold={voiceServiceCount.ktHouseHoldCount}/>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="col">
                                <Card className="card_border">
                                    <CardBody className="px-1 py-3-4">
                                    <div className="row">
                                            <CardLeft className="col-6 bgc-purple icon-box"><HiOutlineWifi size="24"
                                                                                                              color="white"/></CardLeft>
                                            <CardSubTitle className="col-6" title={"SKT 누구"} account={etcCount.sktUserCount} houseHold={voiceServiceCount.sktHouseHoldCount}/>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="col">
                                <Card className="card_border">
                                    <CardBody className="px-1 py-3-4">
                                    <div className="row">
                                            <CardLeft className="col-6 bgc-purple icon-box"><HiOutlineWifi size="24"
                                                                                                              color="white"/></CardLeft>
                                            <CardSubTitle className="col-7" title={"LG U+ ClOVA"} account={etcCount.lgUPlusUserCount} houseHold={voiceServiceCount.lgUPlusHouseHoldCount}/>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="col">
                                <Card className="card_border">

                                    <CardBody className="px-3 py-4-5">
                                        <div className="row">
                                            <CardLeft className="col-6 bgc-purple icon-box"><MdSettingsInputComposite size="24"
                                                                                                              color="white"/></CardLeft>
                                            <CardRight className="col-6" title={"LG 가전 연동 유저 수"} content={etcCount.lgUserCount}/>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="col">
                                <Card className="card_border">

                                    <CardBody className="px-3 py-4-5">
                                        <div className="row">
                                            <CardLeft className="col-md-8 bgc-purple icon-box"><SiSamsung size="36"
                                                                                                              color="white"/></CardLeft>
                                            <CardRight className="col-10 col-sm-6" title={"삼성 가전 연동 유저 수"} content={etcCount.samsungUserCount}/>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>

                        </div>

                        <div className="col-12">
                        <h2 className={cx('mt-4', 'ml-2')}>예약 서비스 현황
                            <FiEdit onClick={onClickReservation} size={24} className="hoverbtn ml-20"/>
                        </h2>
                        <p className="text-muted mt-2 ml-5">{day} [ 당일 예약 기준 ]</p>
                        <p className="mt-2 ml-5 font-user "> * 예약없이 해당 시간이 초과되어도 예약 취급 됩니다.</p>
                        <ReactApexChart options={chartOption} series={chartSeries} type="bar" height={350}/>
                    </div>
                </div>
                </div>
                <div className="col-6 col-md-4">
                    <div className="col">
                        <h2 className={cx('mt-3', 'ml-2', 'mb-20', 'mr-10')}>신규 사용자
                            <FiEdit onClick={onClickUser} size={24} className="hoverbtn ml-20"/>
                        </h2>
                        <div className="card_border bgC-ffffff mb-5 user_card overFlowYscrollView">
                            <h4 className={cx('mt-4', 'ml-2', 'mb-25')}>2일간  가입 사용자 : {yesUser.length}</h4>
                            {
                                yesUser && yesUser.map((item,i)=>{
                                    return(
                                        <div key={i} className="content d-flex align-items-center justify-content-center">
                                            <span className="text-color-purple font-user w-35"> {item.username ? item.username : item.nickname} </span>
                                            <span className="text-color-gray font-user w-15 "> USER </span>
                                            <span className="text-color-gray font-user w-25 "> {item.createdDatetime } </span>
                                            <span className="text-color-purple font-user w-15"> {item.dong} - {item.ho} </span>
                                        </div>
                                    )
                                })
                            }
                            <h4 className={cx('mt-4', 'ml-2', 'mb-25')}>최근 한달 간 가입 사용자 : {newUser.length}</h4>
                            {
                                newUser && newUser.map((item,i)=>{
                                    return(
                                        <div key={i} className="content d-flex align-items-center justify-content-center">
                                            <span className="text-color-purple font-user w-35"> {item.username ? item.username : item.nickname} </span>
                                            <span className="text-color-gray font-user w-15"> USER</span>
                                            <span className="text-color-gray font-user w-25 "> {item.createdDatetime } </span>
                                            <span className="text-color-purple font-user w-15"> {item.dong} - {item.ho} </span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-8">
                    <h2 className="mt-3 ml-2 mb-4">주민 투표 현황
                        <FiEdit onClick={onClickVote} size={24} className="hoverbtn ml-20"/>
                    </h2>
                    {voteState && voteState
                        .map((item, i) => {
                            return (
                                <div key={i} className="pd-3 bgC-ffffff card_border">
                                    <h4 className="ml-15 mt-2 text-muted">{window["VOTE_CLASSIFICATION"] && window["VOTE_CLASSIFICATION"][item.classification]}</h4>
                                    <div className="row ">
                                        <div className="col-6 col-lg-3 col-md-6">
                                            <Card className="card_border">
                                                <CardBody className="px-3 py-3-4">
                                                    <div className="row">
                                                        <CardLeft className="col-md-4 bgc-green icon-box"><GiVote
                                                            size="24"
                                                            color="white"/>
                                                        </CardLeft>
                                                        <CardRight className="col-md-6" title={"전체"}
                                                                   content={item.total}>
                                                        </CardRight>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </div>
                                        <div className="col-6 col-lg-3 col-md-6">
                                            <Card className="card_border">
                                                <CardBody className="px-3 py-3-4">
                                                    <div className="row">
                                                        <CardLeft
                                                            className="col-md-4 bgc-yellow icon-box"><BsStopwatchFill
                                                            size="24"
                                                            color="white"/>
                                                        </CardLeft>
                                                        <CardRight className="col-md-6" title={"대기 건수"}
                                                                   content={item.ready}>
                                                        </CardRight>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </div>
                                        <div className="col-6 col-lg-3 col-md-6">
                                            <Card className="card_border">
                                                <CardBody className="px-3 py-3-4">
                                                    <div className="row">
                                                        <CardLeft
                                                            className="col-md-4 bgc-purple icon-box"><BsFillPersonCheckFill
                                                            size="24"
                                                            color="white"/>
                                                        </CardLeft>
                                                        <CardRight className="col-md-6" title={"진행 건수"}
                                                                   content={item.proceeding}>
                                                        </CardRight>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </div>
                                        <div className="col-6 col-lg-3 col-md-6">
                                            <Card className="card_border">
                                                <CardBody className="px-3 py-3-4">
                                                    <div className="row">
                                                        <CardLeft className="col-md-4 bgc-blue icon-box"><BsXSquareFill
                                                            size="24"
                                                            color="white"/>
                                                        </CardLeft>
                                                        <CardRight className="col-md-6" title={"완료 건수"}
                                                                   content={item.end}>
                                                        </CardRight>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </div>
                                    </div>
                                    <div className="w-100 h-2px bgC-efefef"/>
                                </div>
                            )
                        })
                    }

                </div>
                <div className="col-xl-4">
                    <div className="row mr-10">
                        <h2 className={cx('mt-1', 'ml-2', 'mb-40')}>QnA 처리 현황
                            <FiEdit onClick={onClickQna} size={24} className="hoverbtn ml-20"/>
                        </h2>
                        <div className="card-single mr-20">
                            <div className="row ">
                                {qnaState && qnaState
                                    .map((item, i) => {
                                        return (
                                            <div key={i} className="col-lg-6">
                                                <div
                                                    className="card-head d-flex align-items-center justify-content-between">
                                                    <span
                                                        className="text-color-purple font-user mr-30 fontW-500"> {window["QNA_CLASSIFICATION"] && window["QNA_CLASSIFICATION"][item.classification]} </span>
                                                </div>
                                                <div className="card-body2 justify-content-between">
                                                    <span className="text-color-purple font-user  "> 전체 </span>
                                                    <span
                                                        className="text-color-gray font-user floatR"> {item.total} </span>
                                                    <br/>
                                                    <span className="text-color-purple font-user "> 미처리 </span>
                                                    <span
                                                        className="text-color-gray font-user floatR"> {item.incomplete} </span>
                                                    <br/>
                                                    <span className="text-color-purple font-user "> 처리 </span>
                                                    <span
                                                        className="text-color-gray font-user floatR"> {item.complete} </span>
                                                    <br/>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
