import store from './store';
import axios from 'axios';
import DeviceStorage from 'react-device-storage';
import {Auth} from "aws-amplify";

axios.defaults.baseURL = store.api;
axios.defaults.headers.common['Content-Type'] = 'application/json; charset=UTF-8';

axios.interceptors.request.use(
    function (config) {
        const S = new DeviceStorage().localStorage();
        if (!config.url.includes('/login') && !config.url.includes('/api/kolonbenit') && (S.read('token') !== undefined || store.auth.token !== undefined && store.auth.token !== '')) {
            if (
                config.url.startsWith('/placement/customer/images') ||
                config.url.startsWith('/community/notice/image') ||
                config.url.startsWith('/community/feed/image') ||
                config.url.startsWith('/community/faq/image') ||
                config.url.startsWith('/community/qna/image') ||
                config.url.startsWith('/community/reservation/image') ||
                config.url.startsWith('/community/vote/image') ||
                config.url.startsWith('/community/board/image') ||
                config.url.startsWith('/community/o2o/image') ||
                config.url.startsWith('/files')

            ) {
                // img upload시 content-type을 form-data로 보내야 함.
                axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
            }
        }
        config.headers.Authorization = 'Bearer ' + S.read('token') || store.auth.token;
        return config;
    },

    function (error) {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    function (response) {
        return response;
    },

    function (error) {
        console.log(error )
        if (error.message === "Request failed with status code 401"){
            Auth.federatedSignIn()
        }
        if (error.response !== undefined) {
            if (error.response.status === 401) {
                if (window.location.href.indexOf('/login') < 0) {
                    /*refreshToken((response) => {
                        if (response.status === 200) {
                            console.log(response);
                            window.location.reload();
                        }
                    });*/
                } else if (error.response.data.message === 'Unauthorized') {
                    Auth.federatedSignIn();
                    return error.response;
                }
            } else if (error.response.status == 409) {
                store.alert('중복 된 데이터가 있습니다.\n관리자에게 문의하세요.');
            }
            return error.response;
        }

        if(error.config.timeout && error.config.retry < 2){
            //console.log("retry...", error.config)
            const config = {
                ...error.config,
                retry : error.config.retry+1,
                timeout : error.config.timeout+10000
            }
            return axios.request(config);
        } else{
            return Promise.reject(error);
        }
    }
);


const homenetAxios = axios.create({
    baseURL : store.api
});

const fileAxios = axios.create({
    baseURL : store.api
});



//fileAxios.defaults.headers.common['Content-Type'] = 'application/json; charset=UTF-8';

fileAxios.interceptors.request.use(
    function (config) {
        const S = new DeviceStorage().localStorage();
        config.headers.Authorization = 'Bearer ' + S.read('token') || store.auth.token;
        return config;
    },

    function (error) {
        return Promise.reject(error);
    }
);

homenetAxios.defaults.headers.common['Content-Type'] = 'application/json; charset=UTF-8';

homenetAxios.interceptors.request.use(
    function (config) {
        const S = new DeviceStorage().localStorage();
        if (!config.url.includes('/login') && !config.url.includes('/api/kolonbenit') && (S.read('token') !== undefined || store.auth.token !== undefined && store.auth.token !== '')) {
            if (
                config.url.startsWith('/placement/customer/images') ||
                config.url.startsWith('/community/notice/image') ||
                config.url.startsWith('/community/feed/image') ||
                config.url.startsWith('/community/faq/image') ||
                config.url.startsWith('/community/qna/image') ||
                config.url.startsWith('/community/reservation/image') ||
                config.url.startsWith('/community/vote/image') ||
                config.url.startsWith('/files')

            ) {
                // img upload시 content-type을 form-data로 보내야 함.
                axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
            }
        }
        config.headers.Authorization = 'Bearer ' + S.read('token') || store.auth.token;
        return config;
    },

    function (error) {
        return Promise.reject(error);
    }
);

homenetAxios.interceptors.response.use(
    function (response) {
        return response;
    },

    function (error) {
        return error.response;
        if(error.config.timeout && error.config.retry < 2){
            const config = {
                ...error.config,
                retry : error.config.retry+1,
                timeout : error.config.timeout+500
            }
            return axios.request(config);
        } else{
            return Promise.reject(error);
        }
    }
);


const getUserInfo = async (callback) => {
    await axios
        .get(`/me`)
        .then((response) => {
            callback(response);
        })
        .catch((error) => {
            console.log('error : ' + JSON.stringify(error));
        });
};

const dataURLtoBlob = (dataurl) => {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type: mime});
}

function imageEncode(arrayBuffer) {
    //let u8 = new Uint8Array(arrayBuffer)
    let b64encoded = btoa([].reduce.call(new Uint8Array(arrayBuffer), function (p, c) {
        return p + String.fromCharCode(c)
    }, ''))
    let mimetype = "image/jpeg"
    return "data:" + mimetype + ";base64," + b64encoded
}


function fileEncode(arrayBuffer) {
    //let u8 = new Uint8Array(arrayBuffer)
    let b64encoded = btoa([].reduce.call(new Uint8Array(arrayBuffer), function (p, c) {
        return p + String.fromCharCode(c)
    }, ''))

    //let mimetype = "image/jpeg"
    return "base64," + b64encoded
}


export default {

    // 로그인
    login(id, password, callback) {

        const body = {
            username: id,
            password: password
        }

        axios
            .post(`/login`, body)
            .then((response) => {
                if (response.status === 200) {
                    const DATA = response.data;
                    store.auth = {
                        token: DATA.accessToken,
                        refreshToken: DATA.refreshToken,
                    };

                    const S = new DeviceStorage().localStorage();
                    S.save('token', DATA.accessToken);
                    S.save('refreshToken', DATA.refreshToken);

                    getUserInfo((res) => {
                        store.setUser(res.data);
                        sessionStorage.setItem('user', JSON.stringify(res.data));
                        if (res.status === 200) {
                            store.isAuthorized = true;
                            sessionStorage.setItem('isAuthorized', true);
                            callback(response);
                        } else {
                            return false;
                        }
                    });

                } else {
                    callback(response);
                }

            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 로그아웃
    logout(callback) {
        axios
            .get(`/logout`)
            .then((response) => {
                if (response.status === 200) {
                    Object.keys(store.auth).map(key => {
                        store.user[key] = null;
                    });
                    Object.keys(store.user).map(key => {
                        store.user[key] = null;
                    });
                    store.isAuthorized = false;
                    const S = new DeviceStorage().localStorage();
                    S.delete('token');
                    S.delete('refreshToken');
                    sessionStorage.removeItem('isAuthorized');
                    sessionStorage.removeItem('user');
                }
                Auth.signOut().then(data => {
                    callback(response);
                });

            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    //홈넷 인증 사용자 전체 조회
    getHomenetUserList(pageState, callback) {
        let searchCondition = "";
        searchCondition += pageState.searchCategory2 ? "&siteCode=" + pageState.searchCategory2 : '';
        searchCondition += pageState.searchText ? "&username=" + pageState.searchText : '';
        searchCondition += pageState.searchText2 ? "&dong=" + pageState.searchText2 : '';
        searchCondition += pageState.searchText3 ? "&ho=" + pageState.searchText3 : '';
        axios
            .get(`/homenets/users?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}${searchCondition}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    getUnHomenetUserList(pageState, callback) {
        let searchCondition = "";
        searchCondition += pageState.searchCategory1 ? "&customerCode=" + pageState.searchCategory1 : '';
        axios
            .get(`/account/dashboard/site/user/unApprove?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}${searchCondition}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    //사용자 전체 조회
    getUserList(pageState, callback) {
        let placeName = '';
        placeName += pageState.searchText4 ? pageState.searchText4 + '-' : '-';
        placeName += pageState.searchText5 ? pageState.searchText5 : '';

        let searchCondition = "";
        searchCondition += pageState.searchCategory1 ? "&customer.code=" + pageState.searchCategory1 : '';
        searchCondition += pageState.searchCategory2 ? "&site.code=" + pageState.searchCategory2 : '';
        searchCondition += pageState.searchCategory3 ? "&menuGroup=" + pageState.searchCategory3 : '';
        searchCondition += pageState.searchText ? "&username=" + pageState.searchText : '';
        searchCondition += pageState.searchText2 ? "&nickname=" + pageState.searchText2 : '';
        searchCondition += pageState.searchText3 ? "&phoneNumber=" + pageState.searchText3 : '';
        searchCondition += placeName === '-' ? '' : "&place.name=" + placeName;
        searchCondition += pageState.role ? "&roleList=" + pageState.role : '';

        axios
            .get(`/users?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}${searchCondition}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    //사용자 전체 조회
    getUserListAsync(pageState) {
        let searchCondition = "";
        searchCondition += pageState.searchCategory1 ? "&customer.code=" + pageState.searchCategory1 : '';
        searchCondition += pageState.searchCategory2 ? "&site.code=" + pageState.searchCategory2 : '';
        searchCondition += pageState.searchCategory3 ? "&menuGroup=" + pageState.searchCategory3 : '';
        searchCondition += pageState.searchText ? "&username=" + pageState.searchText : '';
        searchCondition += pageState.role ? "&roleList=" + pageState.role : '';
        return axios
            .get(`/users?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}${searchCondition}`);
    },

    //사용자 상세 조회
    getUserOne(param, callback) {
        axios
            .get(`/users/${param}`)
            .then((response) => {
                if (response.data.picture) {
                    this.getUserImg(response.data.picture, (response2) => {
                        const imageDataUrl = response2.data;
                        const resData = {
                            data: {
                                ...response.data,
                                imageDataUrl: imageDataUrl,
                            },
                            status: 200,
                        }

                        callback(resData);
                    });
                } else {
                    callback(response);
                }
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    getMe(callback) {
        axios
            .get(`/me`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    //사용자 중복체크
    checkUserOne(checkType, param, callback) {
        let searchCondition = "";
        switch (checkType) {
            case "username":
                searchCondition = `check?username=${param.username}`;
                break;
            case "phoneNumber":
                searchCondition = `phone-number/check?phoneNumber=${param.phoneNumber}`;
                break;
            case "nickname":
                searchCondition = `nickname/check?nickname=${param.nickname}`;
                break;
            default:
                break;
        }
        axios
            .get(`/users/${searchCondition}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                if (error.response.status === 409) {
                    callback(error.response);
                } else {
                    console.log('error : ' + JSON.stringify(error));
                }
            });
    },

    // 사용자 추가
    addUser(state, callback) {
        const body = {
            "username": state.username,
            "name": state.name,
            "nickname": state.nickname,
            "contact": state.contact,
            "phoneNumber": state.phoneNumber,
            "password": state.password,
            "status": state.status,
            "customer": {
                "code": state.customer.code,
                "name": state.customer.name,
            },
            "site": {
                "code": state.site.code,
                "name": state.site.name,
            },
            "place": {
                "code": state.place.code,
                "name": state.place.name,
            },
            "menuGroup": state.menuGroup,
            "authList": state.authList,
            "roleList": state.roleList,
            "picture": '',
            "imageDataUrl": state.imageDataUrl,
            "enabled": state.enabled,
            "temporaryPassword": state.temporaryPassword
        }
        if (state.email !== '') {
            body.email = state.email;
        }

        if (state.imageDataUrl !== '') {
            this.UserImgUpload(state, (response) => {
                if (response.status === 200) {
                    const imageId = response.data.id;
                    body.picture = imageId;
                    body.imageDataUrl = '';
                    axios
                        .post(`/signup`, body)
                        .then((response) => {
                            callback(response);
                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        });
                } else if (response === 'error') {
                    body.picture = '';
                    body.imageDataUrl = '';
                    axios
                        .post(`/signup`, body)
                        .then((response) => {
                            callback(response);
                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        });
                }
            });
        } else {
            console.log(body);
            axios
                .post(`/signup`, body)
                .then((response) => {
                    callback(response);
                })
                .catch((error) => {
                    console.log('error : ' + JSON.stringify(error));
                });
        }
    },

    // 사용자 수정
    updateUser(state, callback) {
        const body = {
            "username": state.username,
            "name": state.name,
            "nickname": state.nickname,
            "contact": state.contact,
            "phoneNumber": state.phoneNumber,
            "status": state.status,
            "customer": {
                "code": state.customer.code,
                "name": state.customer.name,
            },
            "site": {
                "code": state.site.code,
                "name": state.site.name,
            },
            "place": {
                "code": state.place.code,
                "name": state.place.name,
            },
            "menuGroup": state.menuGroup,
            "authList": state.authList,
            "roleList": state.roleList,
            "picture": state.picture,
            "imageDataUrl": state.imageDataUrl,
            "enabled": state.enabled,
            "temporaryPassword": state.temporaryPassword

        }

        if (state.email !== '') {
            body.email = state.email;
        }

        if (state.password) {
            body.password = state.password;
        }

        if (state.imageDataUrl !== '') {
            this.UserImgUpload(state, (response) => {
                if (response.status === 200) {
                    const imageId = response.data.id;
                    body.picture = imageId;
                    axios
                        .put(`/users/${state.username}`, body)
                        .then((response) => {
                            callback(response);
                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        });
                } else if (response === 'error') {
                    body.picture = '';
                    axios
                        .put(`/users/${state.username}`, body)
                        .then((response) => {
                            callback(response);
                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        });
                }
            });
        } else {
            console.log(body);
            axios
                .put(`/users/${state.username}`, body)
                .then((response) => {
                    callback(response);
                })
                .catch((error) => {
                    console.log('error : ' + JSON.stringify(error));
                });
        }
    },

    // 사용자 비밀번호 재설정(관리자용)
    changePassword(request, callback) {
        axios
            .put(`/users/change-password`, request)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    //사용자 아이디 체크
    checkUsername(username, callback) {
        homenetAxios
            .get(`users/check?username=${username}`)
            .then((response) => {
                console.log(response);
                callback(response);
            })
            .catch((error) =>{
                console.log(error)
                callback(error);
            });
    },

    // 사용자 - 이미지 가져오기
    getUserImg(image, callback) {
        axios
            .get(`/files/${image}`, {
                responseType: 'arraybuffer'
            })
            .then((response) => {
                response.data = imageEncode(response.data);
                callback(response);
            })
            .catch((error) => {
                console.log('error : ', error);
                callback({data: null});
            })
    },

    // 사용자 - 이미지업로드
    UserImgUpload(formData, callback) {
        let blob = dataURLtoBlob(formData.imageDataUrl);
        let form = new FormData()
        form.append('file', blob);
        form.append('target', 'account-smartiok');
        form.append('path', 'picture');
        form.append('description', '프로필')
        axios
            .post(`/files/upload`, form)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
                if (error.name === 'Error') {
                    callback('error');
                }
            })
    },

    getUserAutomationList(username, callback) {
        axios
            .get(`/automation/user/${username}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                if (error.response.status === 404) {
                    callback(error.response);
                } else {
                    console.log('error : ' + JSON.stringify(error));
                }
            });
    },

    getUserAutomationOne(automationId, callback) {
        axios
            .get(`/automation/${automationId}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    addUserDevice(state, callback) {
        const userDevice = {
            "deviceId": state.deviceId,
            "deviceNickname": state.deviceNickname,
            "spaceId": state.spaceId,
            "placeId": state.placeId,
            "username": state.username
        };

        axios
            .post(`/devices/${userDevice.deviceId}/users`, userDevice)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },


    getUserDeviceList(params, callback) {
        const queryString = params.username ? `?username=${params.username}` : '';
        axios
            .get(`/devices/users` + queryString)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 사용자 - 알림 설정 목록
    getUserNotificationList(username, callback) {
        axios
            .get(`/notifications/configurations/users/${username}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    //시스템 코드 검색
    getSystemCode(codeType, callback) {
        axios
            .get(`/system/code?codeType=${codeType}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },


    //알림 - 알림 로그 조회
    getNotificationLogsList(pageState, callback) {
        let searchCondition = "";
        searchCondition += pageState.searchCategory3 ? "&category=" + pageState.searchCategory3 : '';
        searchCondition += pageState.searchCategory4 ? "&type=" + pageState.searchCategory4 : '';
        searchCondition += pageState.searchStartDate ? "&startTime=" + pageState.searchStartDate : '';
        searchCondition += pageState.searchEndDate ? "&endTime=" + pageState.searchEndDate : '';
        searchCondition += pageState.searchText ? "&username=" + pageState.searchText : '';

        axios
            .get(`/notifications/logs/admin?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}${searchCondition}`)
            .then((response) => {
                console.log(response)
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    //알림 - 현장별 알림 리스트 조회
    getNotificationOne(siteCode, callback) {
        axios
            .get(`/notifications/configurations/sites/${siteCode}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error: ' + JSON.stringify(error));
                callback(error)
            })
    },

    getNotificationCategories(callback) {
        axios
            .get('/notifications/configurations/sites/categories')
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error:' + JSON.stringify(error));
            })
    },

    updateNotificationList(state, callback) {
        console.log(state);
        axios
            .put(`/notifications/configurations/sites/${state.siteCode}`, state)
            .then((response) => {
                console.log(response);
                callback(response);
            })
            .catch((error) => {
                console.log('error: ' + JSON.stringify(error));
            })
    },


    // 사용자 - 유저 삭제
    removeUserList(items, callback) {
        const list = items.map(code => {
            return axios.delete(`/users/${code.username}`)
        });

        axios
            .all(list)
            .then((response) => {
                callback(true);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 장비 - 장비타입 전체 조회
    getToolsTypeList(pageState, callback) {
        axios
            .get(`/devices/types?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}&name=${pageState.searchText}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 장비 - 장비타입 상세 조회
    getToolsTypeOne(code, callback) {
        axios
            .get(`/devices/types/${code}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                if (error.response.status === 404) {
                    callback(error.response);
                } else {
                    console.log('error : ' + JSON.stringify(error));
                }
            });
    },

    // 장비 - 장비타입 등록시 장비타입코드 중복 체크
    chkDuplicateToolsType(code, callback) {
        axios
            // .get(`/devices/types?code=${code}`)
            .get(`/devices/types/${code}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
                callback(error.response);
            });
    },

    // 장비 - 장비타입 삭제
    removeToolsTypeList(items, callback) {
        const list = items.map(code => {
            return axios.delete(`/devices/types/${code}`)
        });

        axios
            .all(list)
            .then((response) => {
                callback(true);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 장비 - 장비타입 추가
    addToolsType(state, callback) {
        const body = {
            "code": state.code,
            "name": state.name,
            "usable": state.usable
        }
        axios
            .post(`/devices/types`, body)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 장비 - 장비타입 수정
    updateToolsType(state, callback) {
        const body = {
            "code": state.code,
            "name": state.name,
            "usable": state.usable
        }
        axios
            .put(`/devices/types/${state.code}`, body)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 장비 - 모델 전체 조회
    getToolsModelList(pageState, callback) {
        let manufacturerCode = '';
        let typeCode = '';
        if (pageState.manufacturer.code !== '' && pageState.manufacturer.code !== undefined) {
            manufacturerCode = `/${pageState.manufacturer.code}`;
        }
        if (pageState.type.code !== '' && pageState.type.code !== undefined) {
            typeCode = `${pageState.type.code}`;
        }

        axios
            .get(`/manufacturers${manufacturerCode}/models?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}&name=${pageState.searchText}&type.code=${typeCode}`)
            .then((response) => {
                console.log(response);
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
                callback(error.response);
            });
    },

    // 장비 - 모델 상세 조회
    getToolsModelOne(params, callback) {
        axios
            .get(`/manufacturers/${params.manufacturerCode}/models/${params.code}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
                callback(error.response);
            });
    },

    // 장비 - 모델 등록시 모델코드 중복 체크
    chkDuplicateToolsModel(params, callback) {
        axios
            // .get(`/manufacturers/models?code=${params.code}`)
            .get(`manufacturers/${params.manufacturer.code}/models/${params.code}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
                callback(error.response);
            });
    },

    // 장비 - 모델 삭제
    removeToolsModelList(items, callback) {
        const list = items.map(codeList => {
            return axios.delete(`/manufacturers/${codeList.manufacturerCode}/models/${codeList.code}`)
        });

        axios
            .all(list)
            .then((response) => {
                callback(true);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 장비 - 모델 추가
    addToolsModel(state, callback) {
        const body = {
            "code": state.code,
            "name": state.name,
            "type": {
                "code": state.type.code
            },
            "attributes":
                state.attributes.map(data => {
                    return ({
                        "id": data.id
                    })
                })
            ,
            "operations":
                state.operations.map(data => {
                    return ({
                        "id": data.id
                    })
                })
            ,
            "automationAttributes":
                state.automationAttributes.map(data => {
                    return ({
                        "id": data.id
                    })
                })
            ,
            "automationOperations":
                state.automationOperations.map(data => {
                    return ({
                        "id": data.id
                    })
                })
            ,
            "usable": state.usable
        }
        axios
            .post(`/manufacturers/${state.manufacturer.code}/models`, body)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 장비 - 모델 수정
    updateToolsModel(state, callback) {
        const body = {
            "code": state.code,
            "name": state.name,
            "type": {
                "code": state.type.code
            },
            "attributes":
                state.attributes.map(data => {
                    return ({
                        "id": data.id
                    })
                })
            ,
            "operations":
                state.operations.map(data => {
                    return ({
                        "id": data.id
                    })
                })
            ,
            "automationAttributes":
                state.automationAttributes.map(data => {
                    return ({
                        "id": data.id
                    })
                })
            ,
            "automationOperations":
                state.automationOperations.map(data => {
                    return ({
                        "id": data.id
                    })
                })
            ,
            "usable": state.usable
        }
        axios
            .put(`/manufacturers/${state.manufacturer.code}/models/${state.code}`, body)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 장비 - 디바이스 전체 조회
    getToolsDeviceList(pageState, callback) {
        const page = pageState.currentPage + 1;
        let searchCondition = '';
        searchCondition += pageState.categoryCode ? `&${pageState.categoryCode}=${pageState.searchText}` : '';
        searchCondition += pageState.searchText2 ? `&updater=${pageState.searchText2}` : '';
        searchCondition += pageState.searchText4 ? `&name=${pageState.searchText4}` : '';

        axios
            .get(`/devices?page=${page}&sizePerPage=${pageState.rowPerPage}${searchCondition}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 장비 - 디바이스 상세 조회
    getToolsDeviceOne(deviceId, callback) {
        axios
            .get(`/devices/${deviceId}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 장비 - 디바이스 삭제
    removeToolsDeviceList(items, callback) {
        const list = items.map(deviceId => {
            return axios.delete(`/devices/${deviceId}`)
        });

        axios
            .all(list)
            .then((response) => {
                callback(true);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 장비 - 디바이스 추가
    addToolsDevice(state, callback) {
        const body = {
            name: state.name,
            model: {
                modelId: state.model.modelId
            },
            serialNumber: state.serialNumber,
            usable: state.usable
        }
        axios
            .post(`/devices`, body)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 장비 - 디바이스 수정
    updateToolsDevice(state, callback) {
        const body = {
            name: state.name,
            model: {
                modelId: state.model.modelId
            },
            serialNumber: state.serialNumber,
            usable: state.usable
        }
        axios
            .put(`/devices/${state.deviceId}`, body)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 장비 - 제조사 전체 조회
    getToolsManufacturerList(pageState, callback) {
        let searchCondition = "";
        searchCondition += pageState.searchText ? "&name=" + pageState.searchText : '';
        axios
            .get(`/manufacturers?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}${searchCondition}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 장비 - 제조사 상세 조회
    getToolsManufacturerOne(code, callback) {
        axios
            .get(`/manufacturers/${code}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 장비 - 제조사 등록시 제조사코드 중복 체크
    chkDuplicateToolsManufacturer(code, callback) {
        axios
            // .get(`/manufacturers?code=${code}`)
            .get(`/manufacturers/${code}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
                callback(error.response);
            });
    },

    // 장비 - 제조사 삭제
    removeToolsManufacturerList(items, callback) {
        const list = items.map(code => {
            return axios.delete(`/manufacturers/${code}`)
        });

        axios
            .all(list)
            .then((response) => {
                callback(true);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 장비 - 제조사 추가
    addToolsManufacturer(state, callback) {
        const body = {
            "code": state.code,
            "name": state.name,
            "usable": state.usable
        }
        axios
            .post(`/manufacturers`, body)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 장비 - 제조사 수정
    updateToolsManufacturer(state, callback) {
        const body = {
            "code": state.code,
            "name": state.name,
            "usable": state.usable
        }
        axios
            .put(`/manufacturers/${state.code}`, body)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 장비 - 속성 전체 조회
    getToolsPropertyList(pageState, callback) {
        axios
            .get(`/models/properties?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}&name=${pageState.searchText}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 장비 - 속성 상세 조회
    getToolsPropertyOne(code, callback) {
        axios
            .get(`/models/properties/${code}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 장비 - 제조사 삭제
    removeToolsPropertyList(items, callback) {
        const list = items.map(code => {
            return axios.delete(`/models/properties/${code}`)
        });

        axios
            .all(list)
            .then((response) => {
                callback(true);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 장비 - 제조사 추가
    addToolsProperty(state, callback) {
        let body = {};
        if (state.valueType === 'RANGE') {
            body = {
                code: state.code,
                name: state.name,
                valueType: state.valueType,
                bottomValue: state.bottomValue,
                topValue: state.topValue,
                resolution: state.resolution,
                unit: state.unit,
            };
        } else if (state.valueType === 'ENUMERATION') {
            body = {
                code: state.code,
                name: state.name,
                valueType: state.valueType,
                availableValues: state.availableValues,
            }
        }
        axios
            .post(`/models/properties`, body)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 장비 - 제조사 수정
    updateToolsProperty(state, callback) {
        let body = {};
        if (state.valueType === 'RANGE') {
            body = {
                code: state.code,
                name: state.name,
                valueType: state.valueType,
                bottomValue: state.bottomValue,
                topValue: state.topValue,
                resolution: state.resolution,
                unit: state.unit
            };
        } else if (state.valueType === 'ENUMERATION') {
            body = {
                code: state.code,
                name: state.name,
                valueType: state.valueType,
                availableValues: state.availableValues,
            }
        }
        axios
            .put(`/models/properties/${state.id}`, body)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 장소 - 고객사 전체 조회
    getCustomerList(pageState, callback) {
        axios
            .get(`/customers?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}&name=${pageState.searchText}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 장소 - 고객사 상세 조회
    getCustomerOne(code, callback) {
        axios
            .get(`/customers/${code}`)
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.imageId && response.data.imageId !== "") {
                        this.getCustomerImg(response.data.imageId, (response2) => {
                            const imageDataUrl = imageEncode(response2.data);
                            const resData = {
                                data: {
                                    ...response.data,
                                    imageDataUrl: imageDataUrl,
                                },
                                status: 200,
                            }
                            callback(resData);
                        });
                    } else {
                        callback(response);
                    }

                }
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 장소 - 고객사 중복 체크
    chkDuplicateCustomer(code, callback) {
        axios
            .get(`/customers/${code}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
                callback(error.response);
            });
    },

    // 장소 - 고객사 삭제
    removeCustomerList(items, callback) {
        const list = items.map(code => {
            return axios.delete(`/customers/${code}`)
        });

        axios
            .all(list)
            .then((response) => {
                callback(true);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 장소 - 고객사 추가
    addCustomer(state, callback) {
        if (state.imageDataUrl !== '') {
            this.customerImgUpload(state, (response) => {
                if (response.status === 200) {
                    const imageId = response.data.id;
                    const body = {
                        ...state,
                        imageId: imageId,
                    }
                    body.imageDataUrl = '';
                    axios
                        .post(`/customers`, body)
                        .then((response) => {
                            callback(response);
                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        })
                } else if (response === 'error') {
                    const body = {
                        ...state,
                        imageId: '',
                    }
                    body.imageDataUrl = '';
                    axios
                        .put(`/customers/${state.code}`, body)
                        .then((response) => {
                            callback(response);
                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        })
                }
            });
        } else {
            const body = {
                ...state,
            }

            axios
                .post(`/customers`, body)
                .then((response) => {
                    callback(response);
                })
                .catch((error) => {
                    console.log('error : ' + JSON.stringify(error));
                })
        }

    },

    // 장소 - 고객사 수정
    updateCustomer(state, callback) {
        if (state.imageDataUrl !== '') {
            this.customerImgUpload(state, (response) => {
                if (response.status === 200) {
                    const imageId = response.data.id;
                    const body = {
                        ...state,
                        imageId: imageId,
                    }
                    body.imageDataUrl = '';
                    axios
                        .put(`/customers/${state.code}`, body)
                        .then((response) => {
                            callback(response);
                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        })
                } else if (response === 'error') {
                    const body = {
                        ...state,
                        imageId: '',
                    }
                    body.imageDataUrl = '';
                    axios
                        .put(`/customers/${state.code}`, body)
                        .then((response) => {
                            callback(response);
                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        })
                }
            });
        } else {
            const body = {
                ...state,
            }

            axios
                .put(`/customers/${state.code}`, body)
                .then((response) => {
                    callback(response);
                })
                .catch((error) => {
                    console.log('error : ' + JSON.stringify(error));
                })
        }
    },

    // 장소 - 고객사 이미지업로드
    customerImgUpload(formData, callback) {
        let blob = dataURLtoBlob(formData.imageDataUrl);
        let form = new FormData()
        form.append('file', blob);
        form.append('target', "placement-smartiok");
        form.append('path', "customer");

        axios
            // .post(`/placement/customer/images`, form)
            .post(`/files/upload`, form)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
                if (error.name === 'Error') {
                    callback('error');
                }
            })
    },

    // 장소 - 고객사 이미지조회
    getCustomerImg(imageId, callback) {
        axios
            // .get(`/placement/customer/images/view/${imageId}`, {
            .get(`/files/${imageId}`, {
                responseType: 'arraybuffer'
            })
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ', error);
                callback({data: null});
            })
    },

    // 장소 - 현장별 동 조회
    getDongList(pageState, callback) {
        axios
            .get(`/master/places/dong/${pageState}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
                callback(error);
            })
    },

    // 장소 - 현장 동별 호 조회
    getHoList(pageState, callback) {
        axios
            .get(`/master/places/ho/${pageState.siteCode}/${pageState.searchCategory3}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
                callback(error);
            })
    },

    // 장소 - 현장 장소 전체 조회
    getSitePlaceListByDong(pageState, dong, callback) {
        let searchCondition = "";
        searchCondition += pageState.siteCode ? "&site.code=" + pageState.siteCode : '';
        searchCondition += pageState.searchText ? "&type=" + pageState.searchText : '';
        searchCondition += dong ? "&dong=" + dong : '';
        axios
            .get(`/sites/places?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}${searchCondition}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                // siteCode param으로 조회시 데이터가 없으면 undefined반환
                console.log('error : ' + JSON.stringify(error));
                callback(error);
            })
    },

    // 장소 - 현장 장소 전체 조회
    getSitePlaceList(pageState, callback) {
        let searchCondition = "";
        searchCondition += pageState.siteCode ? "&site.code=" + pageState.siteCode : '';
        searchCondition += pageState.searchText ? "&type=" + pageState.searchText : '';
        axios
            .get(`/sites/places?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}${searchCondition}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                // siteCode param으로 조회시 데이터가 없으면 undefined반환
                console.log('error : ' + JSON.stringify(error));
                callback(error);
            })
    },
    // 장소 - 현장 장소 상세 조회
    getSitePlaceOne(code, callback) {
        axios
            .get(`/sites/places/${code}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 장소 - 현장 장소 중복 체크
    chkDuplicateSitePlace(state, callback) {
        axios
            .get(`/sites/${state.site.code}/places/${state.dong}/${state.ho}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
                callback(error.response);
            });
    },

    // 장소 - 현장 장소 삭제
    removeSitePlaceList(items, callback) {
        const list = items.map(code => {
            return axios.delete(`/sites/places/${code}`)
        });

        axios
            .all(list)
            .then((response) => {
                callback(true);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 장소 - 현장 장소 추가
    addSitePlace(state, callback) {
        console.log("addSitePlace", state);
        const body = {
            ...state
        }

        axios
            .post(`/sites/places`, body)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    addSitePlaceAsync(state) {
        console.log("addSitePlace", state);
        const body = {
            ...state
        }
        return axios
            .post(`/sites/places`, body);
    },


    // 장소 - 현장 장소 수정
    updateSitePlace(state, callback) {
        const body = {
            ...state
        }

        axios
            .put(`/sites/places/${state.code}`, body)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    getHouseholds(state, callback) {
        let searchCondition = state && "?";
        searchCondition += state.site ? "site.code=" + state.site.code : '';
        searchCondition += state.dong ? "dong=" + state.dong : '';

        axios
            .get(`/sites/places/households${searchCondition}`)
            .then(response => {
                callback(response.data);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 장소 - 현장 전체 조회
    getSitesList(pageState, callback) {
        let searchCondition = "";
        searchCondition += pageState.customerCode ? "&customerCode=" + pageState.customerCode : '';
        axios
            .get(`/sites/admin?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}&name=${pageState.searchText}${searchCondition}`)
            .then((response) => {
                console.log(response);
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 장소 - 현장 전체 조회
    getSitesListAsync(pageState) {
        let searchCondition = "";
        searchCondition += pageState.customerCode ? "&customerCode=" + pageState.customerCode : '';
        return axios
            .get(`/sites/admin?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}&name=${pageState.searchText}${searchCondition}`);
    },

    // 장소 - 현장 상세 조회(codeArray)
    getSitesOne(codeArray, callback) {
        const list = codeArray.map(code => {
            return axios.get(`/sites/${code}`)
        });

        axios
            .all(list)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 장소 - 현장 상세 조회(code)
    getSiteOne(code, callback) {
        axios
            .get(`/sites/${code}`)
            .then((response) => {
                if (response.data.siteImageId && response.data.siteImageId !== "") {
                    this.getSiteImg(response.data.siteImageId, (response2) => {
                        const imageDataUrl = imageEncode(response2.data);
                        const resData = {
                            data: {
                                ...response.data,
                                imageDataUrl: imageDataUrl,
                            },
                            status: 200,
                        }
                        callback(resData);
                    });
                } else {
                    callback(response);
                }
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 장소 - 현장 중복 체크
    chkDuplicateSite(code, callback) {
        axios
            .get(`/sites/${code}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
                callback(error.response);
            });
    },

    // 장소 - 현장 삭제
    removeSitesList(items, callback) {
        const list = items.map(code => {
            return axios.delete(`/sites/${code}`)
        });

        axios
            .all(list)
            .then((response) => {
                console.log(response)
                callback(true);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },


    // 장소 - 현장 추가
    addSite(state, callback) {
        if (state.imageDataUrl !== '') {
            this.siteImgUpload(state, (response) => {
                if (response.status === 200) {
                    const imageId = response.data.id;
                    const body = {
                        ...state,
                        customer: state.customer,
                        region: state.region,
                        siteImageId: imageId,
                    }

                    body.imageDataUrl = '';

                    axios
                        .post(`/sites`, body)
                        .then((response) => {
                            if (state.manufacturerCode !== "") {
                                this.addHomenet(state);
                            }
                            callback(response);
                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        })
                } else if (response === 'error') {
                    const body = {
                        ...state,
                        customer: state.customer,
                        region: state.region,
                        siteImageId: '',
                    }

                    body.imageDataUrl = '';

                    axios
                        .post(`/sites`, body)
                        .then((response) => {
                            if (state.manufacturerCode !== "") {
                                this.addHomenet(state);
                            }
                            callback(response);
                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        })
                }
            });
        } else {
            const body = {
                ...state,
                customer: state.customer,
                region: state.region,
            }

            axios
                .post(`/sites`, body)
                .then((response) => {
                    if (state.manufacturerCode !== "") {
                        this.addHomenet(state);
                    }
                    callback(response);
                })
                .catch((error) => {
                    console.log('error : ' + JSON.stringify(error));
                })
        }
    },

    // 장소 - 현장 수정
    updateSite(state, callback) {
        console.log("updateSite", state);
        if (state.imageDataUrl !== '') {
            this.siteImgUpload(state, (response) => {
                if (response.status === 200) {
                    const imageId = response.data.id;
                    const body = {
                        ...state,
                        customer: state.customer,
                        region: state.region,
                        siteImageId: imageId,
                    }
                    body.imageDataUrl = '';
                    axios
                        .put(`/sites/${state.code}`, body)
                        .then((response) => {
                            if (state.manufacturerCode !== "") {
                                if (state.isHomenetConnect) {
                                    this.updateHomenet(state);
                                } else {
                                    this.addHomenet(state);
                                }
                            }
                            callback(response);
                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        })
                } else if (response === 'error') {
                    const body = {
                        ...state,
                        customer: state.customer,
                        region: state.region,
                        siteImageId: '',
                    }
                    body.imageDataUrl = '';
                    axios
                        .put(`/sites/${state.code}`, body)
                        .then((response) => {
                            if (state.manufacturerCode !== "") {
                                if (state.isHomenetConnect) {
                                    this.updateHomenet(state);
                                } else {
                                    this.addHomenet(state);
                                }
                            }
                            callback(response);
                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        })
                }
            });
        } else {
            const body = {
                ...state,
                customer: state.customer,
                region: state.region,
            }

            axios
                .put(`/sites/${state.code}`, body)
                .then((response) => {
                    if (state.manufacturerCode !== "") {
                        if (state.isHomenetConnect) {
                            console.log(state);
                            this.updateHomenet(state);
                        } else {
                            this.addHomenet(state);
                        }
                    }
                    callback(response);
                })
                .catch((error) => {
                    console.log('error : ' + JSON.stringify(error));
                })
        }
    },

    // 장소 - 현장 이미지업로드
    siteImgUpload(formData, callback) {
        let blob = dataURLtoBlob(formData.imageDataUrl);
        let form = new FormData()
        form.append('file', blob);
        form.append('target', "placement-smartiok");
        form.append('path', "site");

        axios
            .post(`/files/upload`, form)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
                if (error.name === 'Error') {
                    callback('error');
                }
            })
    },

    // 장소 - 현장 이미지조회
    getSiteImg(imageId, callback) {
        axios
            .get(`/files/${imageId}`, {
                responseType: 'arraybuffer'
            })
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 장소 - 현장 홈넷연동정보 조회
    async getHomenetOne(siteCode, callback) {
        await axios
            .get(`/homenets/connection/${siteCode}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                //console.log('error : ' + JSON.stringify(error));
                callback(error.response);
            })
    },


    // 장소 - 현장 홈넷연동정보 삭제
    removeHomenetList(items, callback) {
        const list = items.map(code => {
            return axios.delete(`/homenets/connection/${code}`)
        });

        axios
            .all(list)
            .then((response) => {
                console.log(response)
                callback(true);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 장소 - 현장 홈넷연동정보 추가
    addHomenet(state, callback) {
        const body = {
            siteCode: state.code,
            customerCode: state.customer.code,
            authorizationUrl: state.authorizationUrl,
            controlUrl: state.controlUrl,
            clientId: state.clientId,
            clientSecret: state.clientSecret,
            clientCredentialsId: state.clientCredentialsId,
            clientCredentialsSecret: state.clientCredentialsSecret,
            deviceModelCodes: state.deviceModelCodes,
            noticeConnection: state.noticeConnection
        }

        axios
            .post(`/homenets/connection`, body)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 장소 - 현장 홈넷연동정보 수정
    updateHomenet(state, callback) {
        const body = {
            siteCode: state.code,
            customerCode: state.customer.code,
            authorizationUrl: state.authorizationUrl,
            controlUrl: state.controlUrl,
            clientId: state.clientId,
            clientSecret: state.clientSecret,
            clientCredentialsId: state.clientCredentialsId,
            clientCredentialsSecret: state.clientCredentialsSecret,
            deviceModelCodes: state.deviceModelCodes,
            noticeConnection: state.noticeConnection,
        }

        axios
            .put(`/homenets/connection/${state.code}`, body)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 장소 - 현장 - 시설물 전체 조회
    getFacilityList(pageState) {

        let searchCondition = '';
        searchCondition += pageState.siteCode ? `&site.code=${pageState.siteCode}` : '';
        searchCondition += pageState.searchText ? `&name=${pageState.searchText}` : '';

        return axios
            .get(`/sites/facility?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}${searchCondition}`);
    },

    // 장소 - 현장 - 시설물 상세 조회
    getFacilityOne(code, callback) {
        axios
            .get(`/sites/facility/${code}`)
            .then((response) => {
                if (response.data.imageId && response.data.imageId !== "") {
                    this.getFacilityImg(response.data.imageId, (response2) => {
                        const imageDataUrl = imageEncode(response2.data);
                        const resData = {
                            data: {
                                ...response.data,
                                imageDataUrl: imageDataUrl,
                            },
                            status: 200,
                        }
                        callback(resData);
                    });
                } else {
                    callback(response);
                }
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 장소 - 현장 - 시설물 삭제
    removeFacilityList(items, callback) {
        const list = items.map(code => {
            return axios.delete(`/sites/facility/${code}`)
        });

        axios
            .all(list)
            .then((response) => {
                callback(true);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 장소 - 현장 - 시설물 추가
    addFacility(state, callback) {
        if (state.imageDataUrl !== '') {
            this.facilityImgUpload(state, (response) => {
                if (response.status === 200) {
                    const imageId = response.data.id;
                    const body = {
                        ...state,
                        imageId: imageId,
                    }
                    body.imageDataUrl = '';
                    axios
                        .post(`/sites/facility`, body)
                        .then((response) => {
                            callback(response);
                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        })
                } else if (response === 'error') {
                    const body = {
                        ...state,
                        imageId: '',
                    }

                    axios
                        .post(`/sites/facility`, body)
                        .then((response) => {
                            callback(response);
                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        })
                }
            });
        } else {
            const body = {
                ...state,
            }

            axios
                .post(`/sites/facility`, body)
                .then((response) => {
                    callback(response);
                })
                .catch((error) => {
                    console.log('error : ' + JSON.stringify(error));
                })
        }
    },

    // 장소 - 현장 - 시설물 수정
    updateFacility(state, callback) {
        if (state.imageDataUrl !== '') {
            this.facilityImgUpload(state, (response) => {
                if (response.status === 200) {
                    const imageId = response.data.id;
                    const body = {
                        ...state,
                        imageId: imageId,
                    }
                    body.imageDataUrl = '';
                    axios
                        .put(`/sites/facility/${state.code}`, body)
                        .then((response) => {
                            callback(response);
                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        })
                } else if (response === 'error') {
                    const body = {
                        ...state,
                        imageId: '',
                    }

                    axios
                        .put(`/sites/facility/${state.code}`, body)
                        .then((response) => {
                            callback(response);
                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        })
                }
            });
        } else {
            const body = {
                ...state,
            }

            axios
                .put(`/sites/facility/${state.code}`, body)
                .then((response) => {
                    callback(response);
                })
                .catch((error) => {
                    console.log('error : ' + JSON.stringify(error));
                })
        }
    },

    // 장소 - 현장 - 시설물 이미지업로드
    facilityImgUpload(formData, callback) {
        let blob = dataURLtoBlob(formData.imageDataUrl);
        let form = new FormData()
        form.append('file', blob);
        form.append('target', "placement-smartiok");
        form.append('path', "facility");

        axios
            .post(`/files/upload`, form)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
                if (error.name === 'Error') {
                    callback('error');
                }
            })
    },

    // 장소 - 현장 - 시설물 이미지조회
    getFacilityImg(imageId, callback) {
        axios
            .get(`/files/${imageId}`, {
                responseType: 'arraybuffer'
            })
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 장소 - 현장 - 평면도 전체 조회
    getFloorPlanList(pageState, callback) {

        let searchCondition = '';
        searchCondition += pageState.siteCode ? `&site.code=${pageState.siteCode}` : '';
        searchCondition += pageState.searchText ? `&floorType=${pageState.searchText}` : '';

        axios
            .get(`/sites/floor-plan?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}${searchCondition}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 장소 - 현장 - 평면도 상세 조회
    getFloorPlanOne(code, callback) {
        axios
            .get(`/sites/floor-plan/${code}`)
            .then((response) => {
                if ((response.data.basicImageId && response.data.basicImageId !== "") || (response.data.expandedImageId && response.data.expandedImageId !== "")) {
                    const resData = {
                        data: {
                            ...response.data,
                        },
                        status: 200,
                    }

                    axios.all([this.getFloorPlanImg(response.data.basicImageId), this.getFloorPlanImg(response.data.expandedImageId)])
                        .then(axios.spread(function (t1, t2) {
                            const basicImageDataUrl = t1.data && imageEncode(t1.data);
                            const expandedImageDataUrl = t2.data && imageEncode(t2.data);
                            resData.data = {
                                ...resData.data,
                                basicImageDataUrl: basicImageDataUrl,
                                expandedImageDataUrl: expandedImageDataUrl
                            }

                            console.log(resData);
                            callback(resData);
                        }))
                } else {
                    callback(response);
                }
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 장소 - 현장 - 평면도 삭제
    removeFloorPlanList(items, callback) {
        const list = items.map(code => {
            return axios.delete(`/sites/floor-plan/${code}`)
        });

        axios
            .all(list)
            .then((response) => {
                callback(true);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });

    },

    // 장소 - 현장 - 평면도 추가
    addFloorPlan(state, callback) {

        console.log("addFloorPlan", state);

        if (state.basicImageDataUrl !== '' || state.expandedImageDataUrl !== '') {
            const body = {
                ...state,
            };
            let rtnBasic = false;
            let rtnExpanded = false;
            if (state.basicImageDataUrl !== '') {
                this.floorPlanImgUpload(state.basicImageDataUrl, (response) => {
                    if (response.status === 200) {
                        const imageId = response.data.id;
                        body.basicImageId = imageId;
                        body.basicImageDataUrl = '';
                        rtnBasic = true;
                        sendAxios();
                    } else if (response === 'error') {
                        body.basicImageId = undefined;
                        body.basicImageDataUrl = '';
                        rtnBasic = true;
                        sendAxios();
                    }
                });
            } else {
                body.basicImageId = undefined;
                body.basicImageDataUrl = '';
                rtnBasic = true;
                sendAxios()
            }

            if (state.expandedImageDataUrl !== '') {
                this.floorPlanImgUpload(state.expandedImageDataUrl, (response) => {
                    if (response.status === 200) {
                        const imageId = response.data.id;
                        body.expandedImageId = imageId;
                        body.expandedImageDataUrl = '';
                        rtnExpanded = true;
                        sendAxios()
                    } else if (response === 'error') {
                        body.expandedImageId = undefined;
                        body.expandedImageDataUrl = '';
                        rtnExpanded = true;
                        sendAxios()
                    }
                });
            } else {
                body.expandedImageId = undefined;
                body.expandedImageDataUrl = '';
                rtnExpanded = true;
                sendAxios()
            }

            function sendAxios() {
                if (rtnBasic && rtnExpanded) {

                    axios
                        .post(`/sites/floor-plan`, body)
                        .then((response) => {
                            callback(response);
                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        })
                }
            }

        } else {
            const body = {
                ...state,
            }

            body.basicImageId = undefined;
            body.expandedImageId = undefined;

            axios
                .post(`/sites/floor-plan`, body)
                .then((response) => {
                    callback(response);
                })
                .catch((error) => {
                    console.log('error : ' + JSON.stringify(error));
                })
        }
    },

    // 장소 - 현장 - 평면도 수정
    updateFloorPlan(state, callback) {
        console.log("addFloorPlan", state);

        if (state.basicImageDataUrl !== '' || state.expandedImageDataUrl !== '') {

            const body = {
                ...state,
            };
            let rtnBasic = false;
            let rtnExpanded = false;
            if (state.basicImageDataUrl !== '' && state.basicImageDataUrl !== undefined) {
                this.floorPlanImgUpload(state.basicImageDataUrl, (response) => {
                    if (response.status === 200) {
                        const imageId = response.data.id;
                        body.basicImageId = imageId;
                        body.basicImageDataUrl = '';
                        rtnBasic = true;
                        sendAxios();
                    } else if (response === 'error') {
                        body.basicImageId = undefined;
                        body.basicImageDataUrl = '';
                        rtnBasic = true;
                        sendAxios();
                    }
                });
            } else {
                rtnBasic = true;
                sendAxios()
            }

            if (state.expandedImageDataUrl !== '' && state.expandedImageDataUrl !== undefined) {
                this.floorPlanImgUpload(state.expandedImageDataUrl, (response) => {
                    if (response.status === 200) {
                        const imageId = response.data.id;
                        body.expandedImageId = imageId;
                        body.expandedImageDataUrl = '';
                        rtnExpanded = true;
                        sendAxios()
                    } else if (response === 'error') {
                        body.expandedImageId = undefined;
                        body.expandedImageDataUrl = '';
                        rtnExpanded = true;
                        sendAxios()
                    }
                });
            } else {
                rtnExpanded = true;
                sendAxios()
            }

            function sendAxios() {
                if (rtnBasic && rtnExpanded) {

                    axios
                        .put(`/sites/floor-plan/${state.code}`, body)
                        .then((response) => {
                            callback(response);
                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        })
                }
            }

        } else {
            const body = {
                ...state,
            }

            body.basicImageId = undefined;
            body.expandedImageId = undefined;

            axios
                .put(`/sites/floor-plan/${state.code}`, body)
                .then((response) => {
                    callback(response);
                })
                .catch((error) => {
                    console.log('error : ' + JSON.stringify(error));
                })
        }
    },

    // 장소 - 현장 - 평면도 이미지업로드
    floorPlanImgUpload(imageDataUrl, callback) {
        let blob = dataURLtoBlob(imageDataUrl);
        let form = new FormData()
        form.append('file', blob);
        form.append('target', "placement-smartiok");
        form.append('path', "facility");

        axios
            .post(`/files/upload`, form)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
                if (error.name === 'Error') {
                    callback('error');
                }
            })
    },

    // 장소 - 현장 - 평면도 이미지조회
    getFloorPlanImg(imageId) {
        console.log(imageId);
        if (!imageId) return {data: undefined};
        return axios
            .get(`/files/${imageId}`, {
                responseType: 'arraybuffer'
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 지역코드 전체 조회
    getRegionList(callback) {
        axios
            .get(`/regions`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 설정 - 프로그램 전체 조회
    getProgramList(pageState, callback) {
        axios
            .get(`/system/program?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}&name=${pageState.searchText}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 설정 - 프로그램 삭제
    removeProgramList(items, callback) {
        const list = items.map(code => {
            return axios.delete(`/system/program/${code}`)
        });

        axios
            .all(list)
            .then((response) => {
                callback(true);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 설정 - 프로그램 추가
    addProgram(items, callback) {

        if (items.length > 0) {
            const list = items.map(data => {
                return axios.post(`/system/program`, data)
            });

            axios
                .all(list)
                .then((response) => {
                    callback(true);
                })
                .catch((error) => {
                    console.log('error : ' + JSON.stringify(error));
                });
        }
    },

    // 설정 - 프로그램 수정
    updateProgram(items, callback) {

        if (items.length > 0) {

            const list = items.map(data => {
                return axios.put(`/system/program/${data.code}`, data)
            });

            axios
                .all(list)
                .then((response) => {
                    callback(true);
                })
                .catch((error) => {
                    console.log('error : ' + JSON.stringify(error));
                });
        }
    },

    // 설정 - 메뉴 목록 조회(트리형)
    getMenuTreeList(pageState, callback) {
        axios
            .get(`/system/menu/getTree/${pageState.codeType}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 설정 - 메뉴 상세 조회
    getMenuOne(menuId, callback) {
        axios
            .get(`/system/menu/${menuId}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 설정 - 메뉴 삭제
    removeMenu(menuId, callback) {
        axios
            .delete(`/system/menu/${menuId}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 설정 - 메뉴 등록
    addMenu(menuParam, callback) {
        axios
            .post(`system/menu`, menuParam)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 설정 - 메뉴 수정
    updateMenu(menuParam, callback) {
        axios
            .put(`system/menu/${menuParam.menuId}`, menuParam)
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 설정 - 권한그룹설정 목록(메뉴별)
    getAuthGroupMenuList(menuId, callback) {
        axios
            .get(`/system/authGroupMenu/menu/${menuId}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },


    // 설정 - 권한그룹설정 목록(메뉴별)
    asdf(menuId, callback) {
        axios
            .get(`/master/places/dong/${menuId}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 설정 - 권한그룹설정 목록(메뉴별)
    getAuthGroupMenuOne(menuId, callback) {
        axios
            .get(`/system/authGroupMenu/${menuId}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 설정 - 권한그룹설정 삭제
    removeAuthGroupMenu(authGroupMenuId, callback) {
        axios
            .delete(`/system/authGroupMenu/${authGroupMenuId}`)
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 설정 - 권한그룹설정 등록
    addAuthGroupMenu(addForm, callback) {
        axios
            .post(`/system/authGroupMenu`, addForm)
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 설정 - 권한그룹설정 수정
    updateAuthGroupMenu(authGroupMenuId, updateForm) {
        axios
            .put(`/system/authGroupMenu/${authGroupMenuId}`, updateForm)
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    //설정 - 코드 다중 검색
    getCodeTypeDetailList(items, callback) {
        const list = items.map(code => {
            return axios.get(`/system/code?codeType=${code}`)
        });

        axios
            .all(list)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },


    // 설정 - 코드유형 목록 조회
    getCodeTypeList(pageState, callback) {
        axios
            .get(`/system/codeType?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}&name=${pageState.searchText}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 설정 - 코드유형 상세 조회
    getCodeTypeOne(codeType, callback) {
        axios
            .get(`/system/codeType/${codeType}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 설정 - 코드유형 삭제
    removeCodeTypeList(items, callback) {
        const list = items.map(codeType => {
            return axios.delete(`/system/codeType/${codeType}`)
        });

        axios
            .all(list)
            .then((response) => {
                callback(true);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 설정 - 코드유형 등록
    addCodeType(state, callback) {
        axios
            .post(`/system/codeType`, state)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 설정 - 코드유형 수정
    updateCodeType(state, callback) {
        axios
            .put(`/system/codeType/${state.code}`, state)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 설정 - 코드 목록 조회(타입별)
    getCodeList(codeType, callback) {
        axios
            .get(`/system/code?codeType=${codeType}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 설정 - 코드 목록 조회(타입별)
    getCodeListAsync(codeType) {
        return axios.get(`/system/code?codeType=${codeType}`);
    },

    // 설정 - 코드 상세 조회
    getCodeOne(codeId, callback) {
        axios
            .get(`/system/code/${codeId}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 설정 - 코드 삭제
    removeCode(codeId, callback) {
        axios
            .delete(`/system/code/${codeId}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 설정 - 코드 등록
    addCode(state, callback) {
        axios
            .post(`/system/code`, state)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                if (error.response.status === 409) {
                    callback(error.response);
                }
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 설정 - 코드 수정
    updateCode(state, callback) {
        axios
            .put(`/system/code/${state.id}`, state)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 설정 - 약관 목록
    getPolicyList(pageState, callback) {
        axios
            .get(`/system/policy?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}&customer.code=${pageState.customer.code}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 설정 - 약관 상세
    getPolicyOne(policyId, callback) {
        axios
            .get(`/system/policy/${policyId}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 설정 - 약관 삭제
    removePolicyList(items, callback) {
        const list = items.map(policyId => {
            return axios.delete(`/system/policy/${policyId}`)
        });

        axios
            .all(list)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 설정 - 약관 등록
    addPolicy(state, callback) {
        axios
            .post(`/system/policy`, state)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 설정 - 약관 수정
    updatePolicy(state, callback) {
        axios
            .put(`/system/policy/${state.policyId}`, state)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 이력 - 디바이스 이력 전체 조회(고객사 필수 선택)
    getDeviceHistoryList(pageState, callback) {
        axios
            .get(`/log/history?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}&startTime=${pageState.searchStartDate}&endTime=${pageState.searchEndDate}&deviceId=${pageState.searchText2}&username=${pageState.searchText}&siteCode=${pageState.searchCategory2}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 커뮤니티 Read 정보 조회
    async getReadListAsync(pageState){
        let searchCondition = "";
        let startDate = "";
        let endDate = "";
        if(pageState.startDate.constructor.toString().indexOf("Date") > -1){
            startDate = pageState.startDate.getFullYear() + '-' + ('0' + (Number(pageState.startDate.getMonth()) + 1)).slice(-2) + '-' + ('0' + pageState.startDate.getDate()).slice(-2);
        }
        if(pageState.endDate.constructor.toString().indexOf("Date") > -1){
            endDate = pageState.endDate.getFullYear() + '-' + ('0' + (Number(pageState.endDate.getMonth()) + 1)).slice(-2) + '-' + ('0' + pageState.endDate.getDate()).slice(-2);
        }

        searchCondition += pageState.readId ? "&readId=" + pageState.readId : '';
        searchCondition += pageState.classification ? "&classification=" + pageState.classification : '';
        searchCondition += pageState.parentId ? "&parentId=" + pageState.parentId : '';
        searchCondition += pageState.username ? "&username=" + pageState.username : '';
        searchCondition += pageState.startDate ? "&startDate=" + startDate : '';
        searchCondition += pageState.endDate ? "&endDate=" + endDate : '';

        try {
            return await axios.get(`/community/read?page=0&sizePerPage=9999999${searchCondition}`)
        }catch (err){
            console.log(err);
        }
    },

    //커뮤니티 - 광고 목록
    getBannerList(pageState, callback) {
        let searchCondition = "";
        searchCondition += pageState.searchCategory1 ? "&customer.code=" + pageState.searchCategory1 : '';
        searchCondition += pageState.searchCategory2 ? "&site.code=" + pageState.searchCategory2 : '';
        searchCondition += !pageState.isAllDay ? "&isAllDay=" + !pageState.isAllDay : '';

        axios
            .get(`/community/banner?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}&title=${pageState.searchText}${searchCondition}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    async getBannerLinkListAsync(pageState){
        let searchCondition = "";
        let startDate = "";
        let endDate = "";
        if(pageState.startDate.constructor.toString().indexOf("Date") > -1){
            startDate = pageState.startDate.getFullYear() + '-' + ('0' + (Number(pageState.startDate.getMonth()) + 1)).slice(-2) + '-' + ('0' + pageState.startDate.getDate()).slice(-2);
        }
        if(pageState.endDate.constructor.toString().indexOf("Date") > -1){
            endDate = pageState.endDate.getFullYear() + '-' + ('0' + (Number(pageState.endDate.getMonth()) + 1)).slice(-2) + '-' + ('0' + pageState.endDate.getDate()).slice(-2);
        }
        searchCondition += pageState.bannerLinkId ? "&bannerLinkId=" + pageState.bannerLinkId : '';
        searchCondition += pageState.classification ? "&classification=" + pageState.classification : '';
        searchCondition += pageState.parentId ? "&parentId=" + pageState.parentId : '';
        searchCondition += pageState.username ? "&username=" + pageState.username : '';
        searchCondition += pageState.startDate ? "&startDate=" + startDate : '';
        searchCondition += pageState.endDate ? "&endDate=" + endDate : '';

        try {
            return await axios.get(`/community/banner/link?page=0&sizePerPage=9999999${searchCondition}`)
        }catch (err){
            console.log(err);
        }
    },

    //커뮤니티 - 광고 상세
    getBannerOne(bannerId, callback) {
        axios
            .get(`/community/banner/detail/${bannerId}`)
            .then((response) => {
                console.log(response)
                if (response.data.image) {
                    this.getImg(response.data.image, 'banner', (response2) => {
                        if (response2.status === 200) {
                            const imageDataUrl = imageEncode(response2.data);
                            const resData = {
                                data: {
                                    ...response.data,
                                    imageDataUrl: imageDataUrl,
                                },
                                status: 200,
                            }
                            callback(resData);
                        }
                    });
                } else {
                    callback(response);
                }
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 커뮤니티 - 이미지조회
    getImg(image, classification, callback) {
        axios
            .get(`/community/${classification}/image/view/${image}`, {
                responseType: 'arraybuffer'
            })
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 커뮤니티 - 이미지업로드
    ImgUpload(formData, classification, callback) {
        let blob = dataURLtoBlob(formData.imageDataUrl);
        let form = new FormData()
        form.append('image', blob);

        axios
            .post(`/community/${classification}/image`, form)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
                if (error.name === 'Error') {
                    callback('error');
                }
            })
    },

    async getImgAsync(image, classification) {
        try{
            return await axios .get(`/community/${classification}/image/view/${image}`,{
                responseType: 'arraybuffer'
            })
        }catch (error){
            console.log('error : ' + JSON.stringify(error));
        }
    },

    async ImgUploadAsync(imageUrl, classification) {
        let blob = dataURLtoBlob(imageUrl);
        let form = new FormData()
        form.append('image', blob);

        try{
            return await axios.post(`/community/${classification}/image`, form)
        }catch (error){
            return error;
        }
    },

    async FileUploadAsync(fileUrl, classification) {
        let form = new FormData()
        form.append('file', fileUrl);
        console.log(form);

        try{
            return await axios.post(`/community/${classification}/file`, form)
        }catch (error){
            return error;
        }
    },


    //커뮤니티 - 광고 삭제
    removeBanner(items, callback) {
        const list = items.map(code => {
            return axios.delete(`/community/banner/${code.bannerId}`)
        });

        axios
            .all(list)
            .then((response) => {
                callback(true);
            })
            .catch((error) => {
                callback(true);
                console.log('error : ' + JSON.stringify(error));
            });
    },

    //커뮤니티 - 광고 등록
    addBanner(state, siteList,callback) {
        if (state.imageDataUrl !== '') {
            this.ImgUpload(state, 'banner', (response) => {
                if (response.status === 200) {
                    const imageId = response.data.imageId;
                    const body = {
                        "title": state.title,
                        "contents": state.contents,
                        "customer": {
                            "code": state.customer.code,
                            "name": state.customer.name,
                        },
                        "site": siteList,
                        "startDate": state.startDate,
                        "endDate": state.endDate,
                        "sort" : state.sort,
                        "image": [imageId],
                        "usable": state.usable,
                        "bannerUrl": state.bannerUrl,
                    }
                    axios
                        .post(`/community/banner`, body)
                        .then((response) => {
                            callback(response);
                            console.log(response);
                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        })
                } else if (response === 'error') {
                    const body = {
                        "title": state.title,
                        "contents": state.contents,
                        "customer": {
                            "code": state.customer.code,
                            "name": state.customer.name,
                        },
                        "site": siteList,
                        "startDate": state.startDate,
                        "endDate": state.endDate,
                        "sort" : state.sort,
                        "image": [''],
                        "usable": state.usable,
                        "bannerUrl": state.bannerUrl,
                    }
                    axios
                        .post(`/community/banner`, body)
                        .then((response) => {
                            callback(response);
                            console.log(response);
                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        })
                }
            });
        } else {
            const body = {
                "title": state.title,
                "contents": state.contents,
                "customer": {
                    "code": state.customer.code,
                    "name": state.customer.name,
                },
                "site": siteList,
                "startDate": state.startDate,
                "endDate": state.endDate,
                "sort" : state.sort,
                "image": [],
                "usable": state.usable,
                "bannerUrl": state.bannerUrl,
            }
            axios
                .post(`/community/banner`, body)
                .then((response) => {
                    callback(response);
                })
                .catch((error) => {
                    console.log('error : ' + JSON.stringify(error));
                })
        }
    },

    //커뮤니티 - 광고 수정
    updateBanner(state, siteList, callback) {
        if (state.imageDataUrl !== '') {
            this.ImgUpload(state, 'banner', (response) => {
                if (response.status === 200) {
                    const imageId = response.data.imageId;
                    const body = {
                        "title": state.title,
                        "contents": state.contents,
                        "customer": {
                            "code": state.customer.code,
                            "name": state.customer.name,
                        },
                        "site": siteList,
                        "startDate": state.startDate,
                        "endDate": state.endDate,
                        "sort" : state.sort,
                        "image": [imageId],
                        "usable": state.usable,
                        "bannerUrl": state.bannerUrl,
                    }
                    axios
                        .put(`/community/banner/${state.bannerId}`, body)
                        .then((response) => {
                            callback(response);
                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        })
                } else if (response === 'error') {
                    const body = {
                        "title": state.title,
                        "contents": state.contents,
                        "customer": {
                            "code": state.customer.code,
                            "name": state.customer.name,
                        },
                        "site": siteList,
                        "startDate": state.startDate,
                        "endDate": state.endDate,
                        "sort" : state.sort,
                        "image": [''],
                        "usable": state.usable,
                        "bannerUrl": state.bannerUrl,
                    }
                    axios
                        .put(`/community/banner/${state.bannerId}`, body)
                        .then((response) => {
                            callback(response);
                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        })
                }
            });
        } else {
            const body = {
                "title": state.title,
                "contents": state.contents,
                "customer": {
                    "code": state.customer.code,
                    "name": state.customer.name,
                },
                "site": siteList,
                "startDate": state.startDate,
                "endDate": state.endDate,
                "sort" : state.sort,
                "image": state.image ? state.image : [],
                "usable": state.usable,
                "bannerUrl": state.bannerUrl,
            }
            axios
                .put(`/community/banner/${state.bannerId}`, body)
                .then((response) => {
                    console.log(response)
                    callback(response);
                })
                .catch((error) => {
                    console.log('error : ' + JSON.stringify(error));
                })
        }
    },


    async getO2OLinkList(pageState){
        let searchCondition = "";
        const startDate = pageState.startDate.getFullYear() + '-' + ('0' + (Number(pageState.startDate.getMonth()) + 1)).slice(-2) + '-' + ('0' + pageState.startDate.getDate()).slice(-2);
        const endDate = pageState.endDate.getFullYear() + '-' + ('0' + (Number(pageState.endDate.getMonth()) + 1)).slice(-2) + '-' + ('0' + pageState.endDate.getDate()).slice(-2);

        searchCondition += pageState.classification ? "&classification=" + pageState.classification : '';
        searchCondition += pageState.parentId ? "&parentId=" + pageState.parentId : '';
        searchCondition += pageState.username ? "&username=" + pageState.username : '';
        searchCondition += pageState.startDate ? "&startDate=" + startDate : '';
        searchCondition += pageState.endDate ? "&endDate=" + endDate : '';

        try {
            return await axios.get(`/community/o2o/link?page=0&sizePerPage=9999999${searchCondition}`)
        }catch (err){
            console.log(err);
        }
    },

    //커뮤니티 - 광고 목록
    getO2OList(pageState, callback) {
        let searchCondition = "";
        searchCondition += pageState.searchCategory1 ? "&customer.code=" + pageState.searchCategory1 : '';
        searchCondition += pageState.searchCategory2 ? "&site.code=" + pageState.searchCategory2 : '';
        searchCondition += pageState.searchText ? "&companyName=" + pageState.searchText : '';
        searchCondition += !pageState.isAllDay ? "&isAllDay=" + !pageState.isAllDay : '';
        axios
            .get(`/community/o2o?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}&title=${pageState.searchText}${searchCondition}`)
            .then((response) => {
                callback(null, response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
                callback(JSON.stringify(error));
            })
    },

    //커뮤니티 - 광고 상세
    async getO2OOne(o2oId, callback) {
        let resData = {};
        axios
            .get(`/community/o2o/detail/${o2oId}`)
            .then(async (response) =>  {
                 resData = {
                     ...response.data,
                    status: 200,
                }
                if(response.data.image && response.data.image !== ['']){
                    const response2 = await this.getImgAsync(response.data.image, 'o2o');
                    if(response2.status === 200){
                        const imageDataUrl = await imageEncode(response2.data);
                        resData = {
                            ...resData,
                            imageDataUrl: imageDataUrl,
                            status: 200,
                        }
                    }
                }
                if(response.data.thumbnailImage && response.data.thumbnailImage !== ['']){
                    const response2 = await this.getImgAsync(response.data.thumbnailImage, 'o2o');
                    if(response2.status === 200){
                        const thumbnailImageDataUrl = await imageEncode(response2.data);
                        resData = {
                            ...resData,
                            thumbnailImageDataUrl: thumbnailImageDataUrl,
                            status: 200,
                        }
                    }
                }
                if(response.data.popupImage && response.data.popupImage !== ['']){
                    const response2 = await this.getImgAsync(response.data.popupImage, 'o2o');
                    if(response2.status === 200){
                        const popupImageDataUrl = await imageEncode(response2.data);
                        resData = {
                            ...resData,
                            popupImageDataUrl: popupImageDataUrl,
                            status: 200,
                        }
                    }
                }
                await callback(resData);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    //커뮤니티 - O2O 삭제
    removeO2O(items, callback) {
        const list = items.map(code => {
            return axios.delete(`/community/o2o/${code.o2oId}`)
        });

        axios
            .all(list)
            .then((response) => {
                callback(true);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    async addO2Oasync(state ,updateSite) {
        let imageData = '';
        let thumbnailImageData = '';
        let popupImageData = '';
        if (state.imageDataUrl !== '') {
            imageData = await this.ImgUploadAsync(state.imageDataUrl, 'o2o');
        }
        if (state.thumbnailImageDataUrl !== '') {
            thumbnailImageData = await this.ImgUploadAsync(state.thumbnailImageDataUrl, 'o2o');
        }
        if (state.popupImageDataUrl !== '') {
            popupImageData = await this.ImgUploadAsync(state.popupImageDataUrl, 'o2o');
        }

        async function postO2O(image, thumbnail, popup, state) {
            const body = {
                "o2oServiceName": state.o2oServiceName,
                "o2oServiceOverview": state.o2oServiceOverview,
                "companyName": state.companyName,
                "customer": {
                    "code": state.customer.code,
                    "name": state.customer.name,
                },
                "site":updateSite,
                "startDate": state.startDate,
                "endDate": state.endDate,
                "popupStartDate": state.popupStartDate,
                "popupEndDate": state.popupEndDate,
                "pushNotiDateTime": state.pushNotiDateTime,
                "image": [],
                "thumbnailImage": [],
                "popupImage": [],
                "sort": state.sort,
                "popupSort": state.popupSort,
                "webUrl": state.webUrl,
                "iosUrl": state.iosUrl,
                "aosUrl": state.aosUrl,
            }

            if (image.hasOwnProperty("data")) {
                if (image.data.hasOwnProperty("imageId")) {
                    body.image = [image.data.imageId];
                }
            }

            if (thumbnail.hasOwnProperty("data")) {
                if (thumbnail.data.hasOwnProperty("imageId")) {
                    body.thumbnailImage = [thumbnail.data.imageId];
                }
            }
            if (popup.hasOwnProperty("data")) {
                if (popup.data.hasOwnProperty("imageId")) {
                    body.popupImage = [popup.data.imageId];
                }
            }

            try {
                return await axios.post(`/community/o2o`, body);
            } catch (e) {
                console.log(e);
            }
        }
        console.log(imageData, thumbnailImageData);
        return await postO2O(imageData, thumbnailImageData, popupImageData, state);
    },

    async updateO2OAsync(state, updateSite) {
        let imageData = '';
        let thumbnailImageData = '';
        let popupImageData = '';
        if (state.imageDataUrl !== '') {
            imageData = await this.ImgUploadAsync(state.imageDataUrl, 'o2o');
        }
        if (state.thumbnailImageDataUrl !== '') {
            thumbnailImageData = await this.ImgUploadAsync(state.thumbnailImageDataUrl, 'o2o');
        }
        if (state.popupImageDataUrl !== '') {
            popupImageData = await this.ImgUploadAsync(state.popupImageDataUrl, 'o2o');
        }

        async function postO2O(image, thumbnail, popup, state) {
            const body = {
                "o2oServiceName": state.o2oServiceName,
                "o2oServiceOverview": state.o2oServiceOverview,
                "companyName": state.companyName,
                "customer": {
                    "code": state.customer.code,
                    "name": state.customer.name,
                },
                "site":updateSite,
                "startDate": state.startDate,
                "endDate": state.endDate,
                "popupStartDate": state.popupStartDate,
                "popupEndDate": state.popupEndDate,
                "pushNotiDateTime": state.pushNotiDateTime,
                "image": [],
                "thumbnailImage": [],
                "popupImage": [],
                "sort": state.sort,
                "popupSort": state.popupSort,
                "webUrl": state.webUrl,
                "iosUrl": state.iosUrl,
                "aosUrl": state.aosUrl,
            }

            if (image.hasOwnProperty("data")) {
                if (image.data.hasOwnProperty("imageId")) {
                    body.image = [image.data.imageId];
                }
            }

            if (thumbnail.hasOwnProperty("data")) {
                if (thumbnail.data.hasOwnProperty("imageId")) {
                    body.thumbnailImage = [thumbnail.data.imageId];
                }
            }
            if (popup.hasOwnProperty("data")) {
                if (popup.data.hasOwnProperty("imageId")) {
                    body.popupImage = [popup.data.imageId];
                }
            }

            try {
                return await axios.put(`/community/o2o/${state.o2oId}`, body);
            } catch (e) {
                console.log(e);
            }
        }
        return await postO2O(imageData, thumbnailImageData, popupImageData, state);
    },


    //커뮤니티 - 공지사항 목록
    getNoticeList(pageState, callback) {
        let searchCondition = "";
        searchCondition += pageState.searchCategory1 ? "&customer.code=" + pageState.searchCategory1 : '';
        searchCondition += pageState.searchCategory2 ? "&site.code=" + pageState.searchCategory2 : '';
        searchCondition += pageState.searchCategory3 ? "&classification=" + pageState.searchCategory3 : '';
        axios
            .get(`/community/notice?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}${searchCondition}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    //커뮤니티 - 공지사항 상세
    getNoticeOne(noticeId, callback) {
        axios
            .get(`/community/notice/${noticeId}`)
            .then((response) => {
                if (response.data.image) {
                    this.getNoticeImg(response.data.image, (response2) => {
                        if (response2.status === 200) {
                            const imageDataUrl = imageEncode(response2.data);
                            const resData = {
                                data: {
                                    ...response.data,
                                    imageDataUrl: imageDataUrl,
                                },
                                status: 200,
                            }
                            callback(resData);
                        }
                    });
                } else {
                    callback(response);
                }
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    async getNoticeOneAsync(noticeId){
        try {
            const response = await axios.get(`/community/notice/${noticeId}`);
            let resData = {
                data : {
                    ...response.data
                },
                status : 200
            }
            if(response.data.image){
                const responseImageUrl = await this.getCommunityImg('survey',["64f6eb9016f8c5416d01695e"]);
                console.log(responseImageUrl)
                if(responseImageUrl.status === 200 ){
                    const imageDataUrl = imageEncode(responseImageUrl.data);
                    console.log(responseImageUrl , imageDataUrl)

                    resData = {
                        ...resData,
                        data : {
                            ...resData.data,
                            imageDataUrl: imageDataUrl
                        }
                    }
                }
            }
            if(response.data.file){
                const responseFileUrl = await this.getCommunityFilePath('notice',response.data.file);
                console.log(responseFileUrl)
                if(responseFileUrl.status === 200 ){
                    resData = {
                        ...resData,
                        data : {
                            ...resData.data,
                            fileDataUrl: responseFileUrl.data.filePath,
                            fileName : responseFileUrl.data.fileName
                        }
                    }
                }
            }
            console.log(resData);
            return resData;
        } catch(err) {
            console.log("Error " , err);
        }
    },

    async addNoticeAsync(state){
        let imageData = '';
        let fileData = '';
        let newFilesId = [];

        if (state.imageDataUrl !== '') {
            imageData = await this.ImgUploadAsync(state.imageDataUrl, 'notice');
        }
        if (state.newFiles != null && state.newFiles.length > 0) {
            let resultArray = [];
            for (let i = 0; i < state.newFiles.length; i++) {
                try {
                    const result = await this.FileUploadAsync(state.newFiles[i], 'notice');
                    resultArray.push(result);
                } catch (error){
                    console.error('파일 업로드 에러', error);
                    alert('파일 업로드 에러');
                }
            }
            newFilesId = resultArray.map((obj )=> obj.data.fileId);
        }

        async function PostNotice(image, fileData, state) {
            const body = {
                "classification": state.classification,
                "mainNotice": state.mainNotice,
                "title": state.title,
                "contents": state.contents,
                "customer": {
                    "code": state.customer.code,
                    "name": state.customer.name,
                },
                "site": {
                    "code": state.site.code,
                    "name": state.site.name,
                },
                "dong": state.dong ? state.dong : "ALL",
                "ho": state.ho ? state.ho : "ALL",
                "startDate": state.startDate,
                "endDate": state.endDate,
                "image": [],
                "file": [],
                "publicYn": state.publicYn,
                "isAppClose": state.isAppClose,
                "appPushYn" : state.appPushYn,
                "appPopupYn" : state.appPopupYn,
                "homenetNotiYn" : state.homenetNotiYn
            }

            if (image.hasOwnProperty("data")) {
                if (image.data.hasOwnProperty("imageId")) {
                    body.image = [image.data.imageId];
                }
            }

            if (newFilesId != null && newFilesId.length > 0) {
                body.file = newFilesId;
            }

            try {
                return await axios.post(`/community/notice`, body);
            } catch (e) {
                console.log(e);
            }
        }

        return PostNotice(imageData, fileData, state);
    },

    async updateNoticeAsync(state){
        let imageData = '';
        let fileData = '';
        let filteredFileData = [];
        let newFilesId = [];
        if(state.filteredFiles != null && state.filteredFiles.length > 0){
            filteredFileData = state.filteredFiles.map((obj)=>obj.id);
        }

        if (state.imageDataUrl !== '') {
            imageData = await this.ImgUploadAsync(state.imageDataUrl, 'notice');
        }
        if (state.newFiles != null && state.newFiles.length > 0) {
            let resultArray = [];
            for (let i = 0; i < state.newFiles.length; i++) {
                try {
                    const result = await this.FileUploadAsync(state.newFiles[i], 'notice');
                    resultArray.push(result);
                } catch (error){
                    console.error('파일 업로드 에러', error);
                    alert('파일 업로드 에러');
                }
            }
            newFilesId = resultArray.map((obj )=> obj.data.fileId);
        }


        async function PutNotice(image, fileData, state) {
            const body = {
                "classification": state.classification,
                "mainNotice": state.mainNotice,
                "title": state.title,
                "contents": state.contents,
                "customer": {
                    "code": state.customer.code,
                    "name": state.customer.name,
                },
                "site": {
                    "code": state.site.code,
                    "name": state.site.name,
                },
                "dong": state.dong ? state.dong : "ALL",
                "ho": state.ho ? state.ho : "ALL",
                "startDate": state.startDate,
                "endDate": state.endDate,
                "image": [],
                "file": filteredFileData,
                "publicYn": state.publicYn,
                "isAppClose": state.isAppClose,
                "appPushYn" : state.appPushYn,
                "appPopupYn" : state.appPopupYn,
                "homenetNotiYn" : state.homenetNotiYn
            }

            if (image.hasOwnProperty("data")) {
                if (image.data.hasOwnProperty("imageId")) {
                    body.image = [image.data.imageId];
                }
            }

            if (newFilesId != null && newFilesId.length > 0) {
                body.file = filteredFileData.concat(newFilesId);
            }

            try {
                return await axios.put(`/community/notice/${state.noticeId}`, body);
            } catch (e) {
                console.log(e);
            }
        }

        return PutNotice(imageData, fileData, state);
    },

    //커뮤니티 - 공지사항 등록
    addNotice(state, callback) {
        if (state.imageDataUrl !== '') {
            this.noticeImgUpload(state, (response) => {
                if (response.status === 200) {
                    const imageId = response.data.imageId;
                    const body = {
                        "classification": state.classification,
                        "mainNotice": state.mainNotice,
                        "title": state.title,
                        "contents": state.contents,
                        "customer": {
                            "code": state.customer.code,
                            "name": state.customer.name,
                        },
                        "site": {
                            "code": state.site.code,
                            "name": state.site.name,
                        },
                        "dong": state.dong ? state.dong : "ALL",
                        "ho": state.ho ? state.ho : "ALL",
                        "startDate": state.startDate,
                        "endDate": state.endDate,
                        "image": [imageId],
                        "publicYn": state.publicYn,
                        "isAppClose": state.isAppClose,
                        "appPushYn" : state.appPushYn,
                        "appPopupYn" : state.appPopupYn,
                        "homenetNotiYn" : state.homenetNotiYn
                    }
                    axios
                        .post(`/community/notice`, body)
                        .then((response) => {
                            callback(response);
                            console.log(response);
                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        })
                } else if (response === 'error') {
                    const body = {
                        "classification": state.classification,
                        "mainNotice": state.mainNotice,
                        "title": state.title,
                        "contents": state.contents,
                        "customer": {
                            "code": state.customer.code,
                            "name": state.customer.name,
                        },
                        "site": {
                            "code": state.site.code,
                            "name": state.site.name,
                        },
                        "dong": state.dong ? state.dong : "ALL",
                        "ho": state.ho ? state.ho : "ALL",
                        "startDate": state.startDate,
                        "endDate": state.endDate,
                        "image": [''],
                        "publicYn": state.publicYn,
                        "isAppClose": state.isAppClose,
                        "appPushYn" : state.appPushYn,
                        "appPopupYn" : state.appPopupYn,
                        "homenetNotiYn" : state.homenetNotiYn

                    }
                    axios
                        .post(`/community/notice`, body)
                        .then((response) => {
                            callback(response);
                            console.log(response);
                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        })
                }
            });
        } else {
            const body = {
                "classification": state.classification,
                "mainNotice": state.mainNotice,
                "title": state.title,
                "contents": state.contents,
                "customer": {
                    "code": state.customer.code,
                    "name": state.customer.name,
                },
                "site": {
                    "code": state.site.code,
                    "name": state.site.name,
                },
                "dong": state.dong ? state.dong : "ALL",
                "ho": state.ho ? state.ho : "ALL",
                "startDate": state.startDate,
                "endDate": state.endDate,
                "image": [],
                "publicYn": state.publicYn,
                "isAppClose": state.isAppClose,
                "appPushYn" : state.appPushYn,
                "appPopupYn" : state.appPopupYn,
                "homenetNotiYn" : state.homenetNotiYn

            }
            axios
                .post(`/community/notice`, body)
                .then((response) => {
                    callback(response);
                })
                .catch((error) => {
                    console.log('error : ' + JSON.stringify(error));
                })
        }
    },

    //커뮤니티 - 공지사항 수정
    updateNotice(state, callback) {
        if (state.imageDataUrl !== '') {
            this.noticeImgUpload(state, (response) => {
                if (response.status === 200) {
                    const imageId = response.data.imageId;
                    const body = {
                        "classification": state.classification,
                        "mainNotice": state.mainNotice,
                        "title": state.title,
                        "contents": state.contents,
                        "customer": {
                            "code": state.customer.code,
                            "name": state.customer.name,
                        },
                        "site": {
                            "code": state.site.code,
                            "name": state.site.name,
                        },
                        "dong": state.dong ? state.dong : "ALL",
                        "ho": state.ho ? state.ho : "ALL",
                        "startDate": state.startDate,
                        "endDate": state.endDate,
                        "image": [imageId],
                        "publicYn": state.publicYn,
                        "isAppClose": state.isAppClose,
                        "appPushYn" : state.appPushYn,
                        "appPopupYn" : state.appPopupYn,
                        "homenetNotiYn" : state.homenetNotiYn

                    }
                    axios
                        .put(`/community/notice/${state.noticeId}`, body)
                        .then((response) => {
                            callback(response);
                            console.log(response);
                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        })
                } else if (response === 'error') {
                    const body = {
                        "classification": state.classification,
                        "mainNotice": state.mainNotice,
                        "title": state.title,
                        "contents": state.contents,
                        "customer": {
                            "code": state.customer.code,
                            "name": state.customer.name,
                        },
                        "site": {
                            "code": state.site.code,
                            "name": state.site.name,
                        },
                        "dong": state.dong ? state.dong : "ALL",
                        "ho": state.ho ? state.ho : "ALL",
                        "startDate": state.startDate,
                        "endDate": state.endDate,
                        "image": [''],
                        "publicYn": state.publicYn,
                        "isAppClose": state.isAppClose,
                        "appPushYn" : state.appPushYn,
                        "appPopupYn" : state.appPopupYn,
                        "homenetNotiYn" : state.homenetNotiYn

                    }
                    axios
                        .put(`/community/notice/${state.noticeId}`, body)
                        .then((response) => {
                            callback(response);
                            console.log(response);
                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        })
                }
            });
        } else {
            const body = {
                "classification": state.classification,
                "mainNotice": state.mainNotice,
                "title": state.title,
                "contents": state.contents,
                "customer": {
                    "code": state.customer.code,
                    "name": state.customer.name,
                },
                "site": {
                    "code": state.site.code,
                    "name": state.site.name,
                },
                "dong": state.dong ? state.dong : "ALL",
                "ho": state.ho ? state.ho : "ALL",
                "startDate": state.startDate,
                "endDate": state.endDate,
                "image": state.image ? state.image : [],
                "publicYn": state.publicYn,
                "isAppClose": state.isAppClose,
                "appPushYn" : state.appPushYn,
                "appPopupYn" : state.appPopupYn,
                "homenetNotiYn" : state.homenetNotiYn

            }
            axios
                .put(`/community/notice/${state.noticeId}`, body)
                .then((response) => {
                    console.log(response)
                    callback(response);
                })
                .catch((error) => {
                    console.log('error : ' + JSON.stringify(error));
                })
        }
    },

    // 커뮤니티 - 이미지조회
    getNoticeImg(image, callback) {
        axios
            .get(`/community/notice/image/view/${image}`, {
                responseType: 'arraybuffer'
            })
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 커뮤니티 - 이미지업로드
    noticeImgUpload(formData, callback) {
        let blob = dataURLtoBlob(formData.imageDataUrl);
        let form = new FormData()
        form.append('image', blob);

        axios
            .post(`/community/notice/image`, form)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
                if (error.name === 'Error') {
                    callback('error');
                }
            })
    },

    //커뮤니티 - 공지사항 삭제
    removeNotice(items, callback) {
        const list = items.map(code => {
            return axios.delete(`/community/notice/${code.noticeId}`)
        });

        axios
            .all(list)
            .then((response) => {
                callback(true);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    //커뮤니티 - Feed 등록
    addFeed(state, callback) {
        if (state.imageDataUrl !== '') {
            this.ImgUpload(state, 'feed', (response) => {
                if (response.status === 200) {
                    const imageId = response.data.imageId;
                    const body = {
                        "user": {
                            "username": state.user.username,
                            "userFullName": state.user.nickname,
                            "name": state.user.nickname,
                            "email": state.user.email
                        },
                        "contents": state.contents,
                        "customer": {
                            "code": state.customer.code,
                            "name": state.customer.name,
                        },
                        "site": {
                            "code": state.site.code,
                            "name": state.site.name,
                        },
                        "place": {
                            "code": 'administrator_'+state.site.code,
                            "name": '관리사무소',
                        },
                        "image": [imageId],
                        "publicYn": state.publicYn,
                        "reportedDeleteYn" : state.reportedDeleteYn
                    }
                    axios
                        .post(`/community/feed`, body)
                        .then((response) => {
                            callback(response);
                            console.log(response);
                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        })
                } else if (response === 'error') {
                    const body = {
                        "user": {
                            "username": state.user.username,
                            "userFullName": state.user.nickname,
                            "name": state.user.nickname,
                            "email": state.user.email
                        },
                        "contents": state.contents,
                        "customer": {
                            "code": state.customer.code,
                            "name": state.customer.name,
                        },
                        "site": {
                            "code": state.site.code,
                            "name": state.site.name,
                        },
                        "place": {
                            "code": 'administrator_'+state.site.code,
                            "name": '관리사무소',
                        },
                        "image": [''],
                        "publicYn": state.publicYn

                    }
                    axios
                        .post(`/community/feed`, body)
                        .then((response) => {
                            callback(response);
                            console.log(response);
                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        })
                }
            });
        } else {
            const body = {
                "user": {
                    "username": state.user.username,
                    "userFullName": state.user.nickname,
                    "name": state.user.nickname,
                    "email": state.user.email
                },
                "contents": state.contents,
                "customer": {
                    "code": state.customer.code,
                    "name": state.customer.name,
                },
                "site": {
                    "code": state.site.code,
                    "name": state.site.name,
                },
                "place": {
                    "code": 'administrator_'+state.site.code,
                    "name": '관리사무소',
                },
                "image": [],
                "publicYn": state.publicYn

            }
            axios
                .post(`/community/feed`, body)
                .then((response) => {
                    callback(response);
                })
                .catch((error) => {
                    console.log('error : ' + JSON.stringify(error));
                })
        }
    },

    //커뮤니티 - Feed 상태 변경
    updateFeedOne(state, callback) {
        const body = {
            "user": {
                "username": state.username,
                "userFullName": state.userFullName,
                "picture": state.picture,
                "name": state.name,
                "contact": state.contact,
                "email": state.email
            },
            "contents": state.contents,
            "customer": {
                "code": state.customer.code,
                "name": state.customer.name,
            },
            "site": {
                "code": state.site.code,
                "name": state.site.name,
            },
            "place": {
                "code": state.place.code,
                "name": state.place.name,
            },
            "image": state.image ? state.image : [],
            "publicYn": state.publicYn,
            "reportedDeleteYn" : state.reportedDeleteYn

        }
        console.log(body);
        axios
            .put(`/community/feed/${state.feedId}`, body)
            .then((response) => {
                console.log(response)
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })

    },

    //커뮤니티 - Feed 수정
    updateFeed(state, callback) {
        if (state.imageDataUrl !== '') {
            this.ImgUpload(state, 'feed', (response) => {
                if (response.status === 200) {
                    const imageId = response.data.imageId;
                    const body = {
                        "user": {
                            "username": state.username,
                            "userFullName": state.userFullName,
                            "picture": state.picture,
                            "name": state.name,
                            "contact": state.contact,
                            "email": state.email
                        },
                        "contents": state.contents,
                        "customer": {
                            "code": state.customer.code,
                            "name": state.customer.name,
                        },
                        "site": {
                            "code": state.site.code,
                            "name": state.site.name,
                        },
                        "place": {
                            "code": state.place.code,
                            "name": state.place.name,
                        },
                        "image": [imageId],
                        "publicYn": state.publicYn,
                        "reportedDeleteYn" : state.reportedDeleteYn

                    }
                    axios
                        .put(`/community/feed/${state.feedId}`, body)
                        .then((response) => {
                            callback(response);
                            console.log(response);
                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        })
                } else if (response === 'error') {
                    const body = {
                        "user": {
                            "username": state.username,
                            "userFullName": state.userFullName,
                            "picture": state.picture,
                            "name": state.name,
                            "contact": state.contact,
                            "email": state.email
                        },
                        "contents": state.contents,
                        "customer": {
                            "code": state.customer.code,
                            "name": state.customer.name,
                        },
                        "site": {
                            "code": state.site.code,
                            "name": state.site.name,
                        },
                        "place": {
                            "code": state.place.code,
                            "name": state.place.name,
                        },
                        "image": [''],
                        "publicYn": state.publicYn,
                        "reportedDeleteYn" : state.reportedDeleteYn

                    }
                    axios
                        .put(`/community/feed/${state.feedId}`, body)
                        .then((response) => {
                            callback(response);
                            console.log(response);
                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        })
                }
            });
        } else {
            const body = {
                "user": {
                    "username": state.username,
                    "userFullName": state.userFullName,
                    "picture": state.picture,
                    "name": state.name,
                    "contact": state.contact,
                    "email": state.email
                },
                "contents": state.contents,
                "customer": {
                    "code": state.customer.code,
                    "name": state.customer.name,
                },
                "site": {
                    "code": state.site.code,
                    "name": state.site.name,
                },
                "place": {
                    "code": state.place.code,
                    "name": state.place.name,
                },
                "image": state.image ? state.image : [],
                "publicYn": state.publicYn,
                "reportedDeleteYn" : state.reportedDeleteYn

            }
            console.log(body);
            axios
                .put(`/community/feed/${state.feedId}`, body)
                .then((response) => {
                    console.log(response)
                    callback(response);
                })
                .catch((error) => {
                    console.log('error : ' + JSON.stringify(error));
                })
        }
    },


    //커뮤니티 - Feed 목록
    getFeedList(pageState, callback) {
        let searchCondition = "";
        searchCondition += pageState.searchCategory1 ? "&customer.code=" + pageState.searchCategory1 : '';
        searchCondition += pageState.searchCategory2 ? "&site.code=" + pageState.searchCategory2 : '';
        searchCondition += pageState.searchStartDate ? "&startDate=" + pageState.searchStartDate : '';
        searchCondition += pageState.searchEndDate ? "&endDate=" + pageState.searchEndDate : '';
        axios
            .get(`/community/feed?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}${searchCondition}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    //커뮤니티 - Feed 상세
    getFeedOne(feedId, callback) {
        axios
            .get(`/community/feed/${feedId}`)
            .then((response) => {
                if (response.data.image) {
                    this.getFeedImg(response.data.image, (response2) => {
                        if (response2.status === 200) {
                            const imageDataUrl = imageEncode(response2.data);
                            const resData = {
                                data: {
                                    ...response.data,
                                    imageDataUrl: imageDataUrl,
                                },
                                status: 200,
                            }
                            callback(resData);
                        }
                    });
                } else {
                    callback(response);
                }
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    //커뮤니티 - Feed 상세 이미지 없이
    async getFeedOneNotImage(feedId) {

        try {
            return await axios
                .get(`/community/feed/${feedId}`);
        }catch (err){
            console.log('error : ' + JSON.stringify(err));
        }

    },

    // 커뮤니티 - feed이미지조회
    getFeedImg(image, callback) {
        axios
            .get(`/community/feed/image/view/${image}`, {
                responseType: 'arraybuffer'
            })
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    //커뮤니티 - Feed 댓글 리스트
    getFeedCommentList(pageState, callback) {
        axios
            .get(`/community/feed/${pageState.feedId}/comments/admin?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    //커뮤니티 - Feed 댓글 상세
    async getFeedCommentOne(commentsId) {
        try {
            return await axios
                .get(`/community/feed/parentId/comments/admin/${commentsId}`);
        }catch (err){
            console.log('error : ' + JSON.stringify(err));
        }
    },

    //커뮤니티 - Feed 댓글 등록
    addFeedComment(pageState, callback) {
        const body = {
            "user": {
                "username": pageState.user.username,
                "userFullName": pageState.user.userFullName,
                "name": pageState.user.name,
                "email": pageState.user.email
            },
            "contents": pageState.contents,
            "reportedDeleteYn" : false

        }
        axios
            .post(`/community/${pageState.classification}/${pageState.parentId}/comments`, body)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    //커뮤니티 - Feed 댓글 수정
    updateFeedComment(pageState, callback) {
        const body = {
            "user": {
                "username": pageState.user.username,
                "userFullName": pageState.user.userFullName,
                "name": pageState.user.name,
                "email": pageState.user.email
            },
            "contents": pageState.contents,
            "reportedDeleteYn" : pageState.reportedDeleteYn

        }
        axios
            .put(`/community/${pageState.classification}/${pageState.parentId}/comments/${pageState.commentsId}`, body)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },
    //커뮤니티 - 피드 삭제
    removeFeed(items, callback) {
        const list = items.map(code => {
            return axios.delete(`/community/feed/${code.itemId}`)
        });
        axios
            .all(list)
            .then((response) => {
                callback(true);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },


    //커뮤니티 - Feed 댓글 삭제
    deleteFeedComment(feedId, callback) {
        axios
            .delete(`/community/feed/${feedId[0]}/comments/${feedId[1]}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 커뮤니티 - Feed 신고 목록
    getReportList(pageState, callback) {
        let searchCondition = "";
        searchCondition += pageState.searchCategory1 ? "&classification=" + pageState.searchCategory1 : '';
        searchCondition += pageState.searchText ? "&username=" + pageState.username : '';
        searchCondition += pageState.searchText2 ? "&reportedUsername=" + pageState.reportedUsername : '';
        axios
            .get(`/community/report/admin?page=${pageState.currentPage+1}&sizePerPage=${pageState.rowPerPage}${searchCondition}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    //커뮤니티 - Feed 차단 목록
    getBlockList(pageState, callback) {
        let searchCondition = "";
        searchCondition += pageState.searchText ? "&username=" + pageState.searchText : '';
        searchCondition += pageState.searchText2 ? "&blockedUsername=" + pageState.searchText2 : '';
        axios
            .get(`/community/block/admin?page=${pageState.currentPage+1}&sizePerPage=${pageState.rowPerPage}${searchCondition}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    //커뮤니티 - Feed 차단 수정
    updateBlockList(items, callback) {
        const list = items.map(code => {
            return axios.put(`/community/block/${code.blockId}`,code)
        });

        axios
            .all(list)
            .then((response) => {
                console.log(response);
                callback(true);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    //커뮤니티 - FAQ 리스트
    getFaqList(pageState, callback) {
        let searchCondition = "";
        searchCondition += pageState.searchCategory1 ? "&customer.code=" + pageState.searchCategory1 : '';
        searchCondition += pageState.searchCategory2 ? "&site.code=" + pageState.searchCategory2 : '';
        searchCondition += pageState.searchCategory3 ? "&classification=" + pageState.searchCategory3 : '';
        searchCondition += pageState.searchText ? "&title=" + pageState.searchText : '';
        axios
            .get(`/community/faq?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}${searchCondition}`)
            .then((response) => {
                console.log(response)
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    //커뮤니티 - FAQ 상세
    getFaqOne(faqId, callback) {
        axios
            .get(`/community/faq/${faqId}`)
            .then((response) => {
                if (response.data.image) {
                    this.getFaqImg(response.data.image, (response2) => {
                        if (response2.status === 200) {
                            const imageDataUrl = imageEncode(response2.data);
                            const resData = {
                                data: {
                                    ...response.data,
                                    imageDataUrl: imageDataUrl,
                                },
                                status: 200,
                            }
                            callback(resData);
                        }
                    });
                } else {
                    callback(response);
                }
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 커뮤니티 - FAQ 이미지 가져오기
    getFaqImg(image, callback) {
        axios
            .get(`/community/faq/image/view/${image}`, {
                responseType: 'arraybuffer'
            })
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 커뮤니티 - FAQ 이미지업로드
    faqImgUpload(formData, callback) {
        let blob = dataURLtoBlob(formData.imageDataUrl);
        let form = new FormData()
        form.append('image', blob);

        axios
            .post(`/community/faq/image`, form)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
                if (error.name === 'Error') {
                    callback('error');
                }
            })
    },

    //커뮤니티 - FAQ 등록
    addFaq(state, callback) {
        if (state.imageDataUrl !== '') {
            this.faqImgUpload(state, (response) => {
                if (response.status === 200) {
                    const imageId = response.data.imageId;
                    const body = {
                        "classification": state.classification,
                        "title": state.title,
                        "contents": state.contents,
                        "customer": {
                            "code": state.customer.code,
                            "name": state.customer.name,
                        },
                        "site": {
                            "code": state.site.code,
                            "name": state.site.name,
                        },
                        "image": [imageId],
                        "publicYn": state.publicYn
                    }
                    axios
                        .post(`/community/faq`, body)
                        .then((response) => {
                            callback(response);
                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        })
                } else if (response === 'error') {
                    const body = {
                        "classification": state.classification,
                        "title": state.title,
                        "contents": state.contents,
                        "customer": {
                            "code": state.customer.code,
                            "name": state.customer.name,
                        },
                        "site": {
                            "code": state.site.code,
                            "name": state.site.name,
                        },
                        "image": [''],
                        "publicYn": state.publicYn
                    }
                    axios
                        .post(`/community/faq`, body)
                        .then((response) => {
                            callback(response);
                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        })
                }
            });
        } else {
            const body = {
                "classification": state.classification,
                "title": state.title,
                "contents": state.contents,
                "customer": {
                    "code": state.customer.code,
                    "name": state.customer.name,
                },
                "site": {
                    "code": state.site.code,
                    "name": state.site.name,
                },
                "image": [],
                "publicYn": state.publicYn
            }
            axios
                .post(`/community/faq`, body)
                .then((response) => {
                    callback(response);
                })
                .catch((error) => {
                    console.log('error : ' + JSON.stringify(error));
                })
        }
    },

    //커뮤니티 - FAQ 수정
    updateFaq(state, callback) {
        if (state.imageDataUrl !== '') {
            this.faqImgUpload(state, (response) => {
                if (response.status === 200) {
                    const imageId = response.data.imageId;
                    const body = {
                        "classification": state.classification,
                        "title": state.title,
                        "contents": state.contents,
                        "customer": {
                            "code": state.customer.code,
                            "name": state.customer.name,
                        },
                        "site": {
                            "code": state.site.code,
                            "name": state.site.name,
                        },
                        "image": [imageId],
                        "publicYn": state.publicYn
                    }
                    axios
                        .put(`/community/faq/${state.faqId}`, body)
                        .then((response) => {
                            callback(response);
                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        })
                } else if (response === 'error') {
                    const body = {
                        "classification": state.classification,
                        "title": state.title,
                        "contents": state.contents,
                        "customer": {
                            "code": state.customer.code,
                            "name": state.customer.name,
                        },
                        "site": {
                            "code": state.site.code,
                            "name": state.site.name,
                        },
                        "image": [''],
                        "publicYn": state.publicYn
                    }
                    axios
                        .put(`/community/faq/${state.faqId}`, body)
                        .then((response) => {
                            callback(response);
                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        })
                }
            });
        } else {
            const body = {
                "classification": state.classification,
                "title": state.title,
                "contents": state.contents,
                "customer": {
                    "code": state.customer.code,
                    "name": state.customer.name,
                },
                "site": {
                    "code": state.site.code,
                    "name": state.site.name,
                },
                "image": state.image ? state.image : [],
                "publicYn": state.publicYn
            }
            axios
                .put(`/community/faq/${state.faqId}`, body)
                .then((response) => {
                    callback(response);
                })
                .catch((error) => {
                    console.log('error : ' + JSON.stringify(error));
                })
        }
    },

    //커뮤니티 - FAQ 삭제
    removeFaq(items, callback) {
        const list = items.map(code => {
            return axios.delete(`/community/faq/${code.faqId}`)
        });

        axios
            .all(list)
            .then((response) => {
                callback(true);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    //커뮤니티 - QaA 리스트
    getQnaList(pageState, callback) {
        let searchCondition = "";
        searchCondition += pageState.searchCategory1 ? "&customer.code=" + pageState.searchCategory1 : '';
        searchCondition += pageState.searchCategory2 ? "&site.code=" + pageState.searchCategory2 : '';
        searchCondition += pageState.searchCategory3 ? "&classification=" + pageState.searchCategory3 : '';
        searchCondition += pageState.searchText ? "&title=" + pageState.searchText : '';

        axios
            .get(`/community/qna?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}${searchCondition}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    //커뮤니티 - QaA 상세
    getQnaOne(questionId, callback) {
        axios
            .get(`/community/qna/${questionId}`)
            .then((response) => {
                if (response.data.image) {
                    this.getQnaImg(response.data.image, (response2) => {
                        const imgeDataUrlList = [];
                        response2.map(img => {
                            if (img.status === 200) {
                                const imageDataUrl = imageEncode(img.data);
                                imgeDataUrlList.push(imageDataUrl);
                            }

                        })
                        const resData = {
                            data: {
                                ...response.data,
                                imageDataUrl: imgeDataUrlList,
                            },
                            status: 200,
                        }
                        callback(resData);

                    });
                } else {
                    callback(response);
                }
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    //커뮤니티 - QaA 이미지 가져오기
    getQnaImg(image, callback) {
        const list = image.map(img => {
            return axios.get(`/community/qna/image/view/${img}`, {
                responseType: 'arraybuffer'
            })
        });
        axios
            .all(list)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    //커뮤니티 - QaA 답변 리스트
    getQnaAnswerList(questionId, callback) {
        axios
            .get(`/community/qna/${questionId}/answer`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })

    },

    //커뮤니티 - QaA 답변 상세
    getQnaAnswerOne(state, callback) {
        axios
            .get(`/community/qna/${state.questionId}/answer/${state.answerId}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })

    },

    getQnaStatus(state, callback) {
        axios
            .get(`/community/qna/status?site.code=${state}`)
            .then((response) => {
                callback(response.data);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })

    },

    //커뮤니티 - QaA 답변 등록
    addQnaAnswer(state, callback) {
        const body = {
            contents: state.contents,
            finished: state.finished,
            user: {
                username: state.userInfo.username,
                userFullName: state.userInfo.userFullName,
                picture: state.userInfo.picture,
                name: state.userInfo.name,
                contact: state.userInfo.contact,
                email: state.userInfo.email
            }
        }
        axios
            .post(`/community/qna/${state.questionId}/answer`, body)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })

    },

    //커뮤니티 - QaA 답변 수정
    updateQnaAnswer(state, callback) {
        const body = {
            contents: state.contents,
            finished: state.finished,
            user: {
                username: state.userInfo.username,
                userFullName: state.userInfo.userFullName,
                picture: state.userInfo.picture,
                name: state.userInfo.name,
                contact: state.userInfo.contact,
                email: state.userInfo.email
            }
        }
        axios
            .put(`/community/qna/${state.questionId}/answer/${state.answerId}`, body)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })

    },

    //커뮤니티 - QaA 답변 삭제
    removeQnaAnswer(items, callback) {
        const list = items.map(item => {
            return axios.delete(`/community/qna/${item.questionId}/answer/${item.answerId}`)
        });

        axios
            .all(list)
            .then((response) => {
                callback(true);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 장비 - 디바이스 삭제
    removeQnaList(items, callback) {
        const list = items.map(questionId => {
            return axios.delete(`/community/qna/${questionId}`)
        });

        axios
            .all(list)
            .then((response) => {
                callback(true);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    //커뮤니티 - 유형코드 리스트 검색
    getCommunityCodeTypeList(pageState, callback) {
        let searchCondition = "";
        searchCondition += pageState.searchText ? "&name=" + pageState.searchText : '';
        axios
            .get(`/community/codeType?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}${searchCondition}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    //커뮤니티 - 유형코드 리스트 검색
    getCommunityCodeTypeListAsync(pageState) {
        let searchCondition = "";
        searchCondition += pageState.searchText ? "&name=" + pageState.searchText : '';
        return axios
            .get(`/community/codeType?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}${searchCondition}`);
    },

    //커뮤니티 - 유형코드 상세 검색
    getCommunityCodeTypeOne(code, callback) {
        axios
            .get(`/community/codeType/${code}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 커뮤니티 - 유형코드 저장
    addCommunityCodeType(state, callback) {
        const body = {
            code: state.code,
            name: state.name,
            usable: state.usable,
        }

        axios
            .post(`/community/codeType`, body)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 커뮤니티 - 유형코드 수정
    updateCommunityCodeType(state, callback) {
        const body = {
            name: state.name,
            usable: state.usable,
        }

        axios
            .put(`/community/codeType/${state.code}`, body)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    //커뮤니티 - 유형코드 삭제
    removeCommunityCodeType(items, callback) {
        const list = items.map(code => {
            return axios.delete(`/community/codeType/${code.code}`)
        });

        axios
            .all(list)
            .then((response) => {
                callback(true);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    //커뮤니티 - 코드 다중 검색
    getCommunityCodeList(items, callback) {
        const list = items.map(code => {
            return axios.get(`/community/code?codeType=${code.code}`)
        });

        axios
            .all(list)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },


    //커뮤니티 - 코드 검색
    getCommunityCode(codeType, callback) {
        axios
            .get(`/community/code?codeType=${codeType}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    async getCommunityCodeAsync(codeType) {
        return await axios
            .get(`/community/code?codeType=${codeType}`);
    },

    //커뮤니티 - 코드 검색
    getCommunityCodeOne(id, callback) {
        axios
            .get(`/community/code/${id}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 커뮤니티 - 코드 저장
    addCommunityCode(state, callback) {
        const body = {
            codeType: state.codeType,
            code: state.code,
            name: state.name,
            attribute1: state.attribute1,
            attribute2: state.attribute2,
            attribute3: state.attribute3,
            attribute4: state.attribute4,
            attribute5: state.attribute5,
            sort: state.sort,
            usable: state.usable,
        }

        axios
            .post(`/community/code`, body)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
                if (error.response.status === 409) {
                    callback(error.response);
                }
            })
    },

    // 커뮤니티 - 코드 수정
    updateCommunityCode(state, callback) {
        const body = {
            codeType: state.codeType,
            code: state.code,
            name: state.name,
            attribute1: state.attribute1,
            attribute2: state.attribute2,
            attribute3: state.attribute3,
            attribute4: state.attribute4,
            attribute5: state.attribute5,
            sort: state.sort,
            usable: state.usable,
        }

        axios
            .put(`/community/code/${state.id}`, body)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    //커뮤니티 - 코드 삭제
    removeCommunityCode(items, callback) {
        const list = items.map(code => {
            return axios.delete(`/community/code/${code.id}`)
        });

        axios
            .all(list)
            .then((response) => {
                callback(true);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    //커뮤니티 - 예약 리스트 검색
    getReservationList(pageState, callback) {
        let searchCondition = "";
        searchCondition += pageState.searchCategory1 ? "&customer.code=" + pageState.searchCategory1 : '';
        searchCondition += pageState.searchCategory2 ? "&site.code=" + pageState.searchCategory2 : '';
        searchCondition += pageState.searchCategory3 ? "&groupCode=" + pageState.searchCategory3 : '';
        axios
            .get(`/community/reservation/item?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}${searchCondition}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    //커뮤니티 - 예약 상세 검색
    getReservationOne(itemId, callback) {
        axios
            .get(`/community/reservation/item/${itemId}`)
            .then((response) => {
                if (response.data.image) {
                    this.getReservationImg(response.data.image, (response2) => {
                        if (response2.status === 200) {
                            const imageDataUrl = imageEncode(response2.data);
                            const resData = {
                                data: {
                                    ...response.data,
                                    imageDataUrl: imageDataUrl,
                                },
                                status: 200,
                            }
                            callback(resData);
                        }
                    });
                } else {
                    callback(response);
                }
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    //커뮤니티 - 예약 휴일 검색
    getHolidayList(itemId, callback) {
        axios
            .get(`/community/reservation/item/${itemId}/holiday`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    //커뮤니티 - 예약 현황 목록
    getReservationConditionList(pageState, callback) {
        let searchCondition = "";
        searchCondition += pageState.searchCategory1 ? "&status=" + pageState.searchCategory1 : '';
        searchCondition += pageState.searchText ? "&user.username=" + pageState.searchText : '';
        searchCondition += pageState.searchStartDate ? "&startDate=" + pageState.searchStartDate : '';
        searchCondition += pageState.searchEndDate ? "&endDate=" + pageState.searchEndDate : '';
        console.log(pageState)
        axios
            .get(`/community/reservation/item/${pageState.itemId}/reservation?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}${searchCondition}`)
            .then((response) => {
                console.log(response);
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 커뮤니티 - 예약 이미지 가져오기
    getReservationImg(image, callback) {
        axios
            .get(`/community/reservation/image/view/${image}`, {
                responseType: 'arraybuffer'
            })
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 커뮤니티 - 예약 이미지업로드
    reservationImgUpload(formData, callback) {
        let blob = dataURLtoBlob(formData.imageDataUrl);
        let form = new FormData()
        form.append('image', blob);

        axios
            .post(`/community/reservation/image`, form)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
                if (error.name === 'Error') {
                    callback('error');
                }
            })
    },

    //커뮤니티 - 예약 등록
    addReservation(state, callback) {
        if (state.imageDataUrl !== '') {
            this.reservationImgUpload(state, (response) => {
                if (response.status === 200) {
                    const imageId = response.data.imageId;
                    const body = {
                        "name": state.name,
                        "description": state.description,
                        "customer": {
                            "code": state.customer.code,
                            "name": state.customer.name,
                        },
                        "site": {
                            "code": state.site.code,
                            "name": state.site.name,
                        },
                        "groupCode": {
                            "code": state.groupCode.code,
                        },
                        "period": state.period,
                        "startDate": state.startDate,
                        "endDate": state.endDate,
                        "startHour": state.startHour,
                        "endHour": state.endHour,
                        "timePeriod": state.timePeriod,
                        "image": [imageId],
                        "usable": state.usable
                    }
                    axios
                        .post(`/community/reservation/item`, body)
                        .then((response) => {
                            if (response.status === 200 || response.status === 201) {
                                let holidayBody = {};
                                const list = state.newHoliday.map(hDay => {
                                    holidayBody = {
                                        "holiday": hDay.holiday,
                                    }
                                    return axios.post(`/community/reservation/item/${response.data.itemId}/holiday`, holidayBody)
                                });
                                axios
                                    .all(list)
                                    .then((response2) => {
                                        callback(response);
                                    })
                                    .catch((error) => {
                                        console.log('error : ' + JSON.stringify(error));
                                    })
                            }
                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        })
                } else if (response === 'error') {
                    const body = {
                        "name": state.name,
                        "description": state.description,
                        "customer": {
                            "code": state.customer.code,
                            "name": state.customer.name,
                        },
                        "site": {
                            "code": state.site.code,
                            "name": state.site.name,
                        },
                        "groupCode": {
                            "code": state.groupCode.code,
                        },
                        "period": state.period,
                        "startDate": state.startDate,
                        "endDate": state.endDate,
                        "startHour": state.startHour,
                        "endHour": state.endHour,
                        "timePeriod": state.timePeriod,
                        "image": [''],
                        "usable": state.usable
                    }
                    axios
                        .post(`/community/reservation/item`, body)
                        .then((response) => {
                            if (response.status === 200 || response.status === 201) {
                                let holidayBody = {};
                                const list = state.newHoliday.map(hDay => {
                                    holidayBody = {
                                        "holiday": hDay.holiday,
                                    }
                                    return axios.post(`/community/reservation/item/${response.data.itemId}/holiday`, holidayBody)
                                });
                                axios
                                    .all(list)
                                    .then((response2) => {
                                        callback(response);
                                    })
                                    .catch((error) => {
                                        console.log('error : ' + JSON.stringify(error));
                                    })
                            }
                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        })
                }
            });
        } else {
            const body = {
                "name": state.name,
                "description": state.description,
                "customer": {
                    "code": state.customer.code,
                    "name": state.customer.name,
                },
                "site": {
                    "code": state.site.code,
                    "name": state.site.name,
                },
                "groupCode": {
                    "code": state.groupCode.code,
                },
                "period": state.period,
                "startDate": state.startDate,
                "endDate": state.endDate,
                "startHour": state.startHour,
                "endHour": state.endHour,
                "timePeriod": state.timePeriod,
                "image": [],
                "usable": state.usable
            }
            axios
                .post(`/community/reservation/item`, body)
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        let holidayBody = {};
                        const list = state.newHoliday.map(hDay => {
                            holidayBody = {
                                "holiday": hDay.holiday,
                            }
                            return axios.post(`/community/reservation/item/${response.data.itemId}/holiday`, holidayBody)
                        });
                        axios
                            .all(list)
                            .then((response2) => {
                                callback(response);
                            })
                            .catch((error) => {
                                console.log('error : ' + JSON.stringify(error));
                            })
                    }
                })
                .catch((error) => {
                    console.log('error : ' + JSON.stringify(error));
                })
        }
    },

    //커뮤니티 - 예약 수정
    updateReservation(state, callback) {
        if (state.imageDataUrl !== '') {
            this.reservationImgUpload(state, (response) => {
                if (response.status === 200) {
                    const imageId = response.data.imageId;
                    const body = {
                        "name": state.name,
                        "description": state.description,
                        "customer": {
                            "code": state.customer.code,
                            "name": state.customer.name,
                        },
                        "site": {
                            "code": state.site.code,
                            "name": state.site.name,
                        },
                        "groupCode": {
                            "code": state.groupCode.code,
                        },
                        "period": state.period,
                        "startDate": state.startDate,
                        "endDate": state.endDate,
                        "startHour": state.startHour,
                        "endHour": state.endHour,
                        "timePeriod": state.timePeriod,
                        "image": [imageId],
                        "usable": state.usable
                    }
                    axios
                        .put(`/community/reservation/item/${state.itemId}`, body)
                        .then((response) => {
                            if (response.status === 200) {
                                this.updateRegistRemoveHoliday(state, (cb) => {
                                    if (cb) {
                                        callback(response);
                                    }
                                });
                            }
                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        })
                } else if (response === 'error') {
                    const body = {
                        "name": state.name,
                        "description": state.description,
                        "customer": {
                            "code": state.customer.code,
                            "name": state.customer.name,
                        },
                        "site": {
                            "code": state.site.code,
                            "name": state.site.name,
                        },
                        "groupCode": {
                            "code": state.groupCode.code,
                        },
                        "period": state.period,
                        "startDate": state.startDate,
                        "endDate": state.endDate,
                        "startHour": state.startHour,
                        "endHour": state.endHour,
                        "timePeriod": state.timePeriod,
                        "image": [''],
                        "usable": state.usable
                    }
                    axios
                        .put(`/community/reservation/item/${state.itemId}`, body)
                        .then((response) => {
                            if (response.status === 200) {
                                this.updateRegistRemoveHoliday(state, (cb) => {
                                    if (cb) {
                                        callback(response);
                                    }
                                });
                            }
                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        })
                }
            });
        } else {
            const body = {
                "name": state.name,
                "description": state.description,
                "customer": {
                    "code": state.customer.code,
                    "name": state.customer.name,
                },
                "site": {
                    "code": state.site.code,
                    "name": state.site.name,
                },
                "groupCode": {
                    "code": state.groupCode.code,
                },
                "period": state.period,
                "startDate": state.startDate,
                "endDate": state.endDate,
                "startHour": state.startHour,
                "endHour": state.endHour,
                "timePeriod": state.timePeriod,
                "image": state.image ? state.image : [],
                "usable": state.usable
            }
            axios
                .put(`/community/reservation/item/${state.itemId}`, body)
                .then((response) => {
                    if (response.status === 200) {
                        this.updateRegistRemoveHoliday(state, (cb) => {
                            if (cb) {
                                callback(response);
                            }
                        });
                    }
                })
                .catch((error) => {
                    console.log('error : ' + JSON.stringify(error));
                })
        }
    },

    updateRegistRemoveHoliday(state, cb) {
        let result = true;
        const originHoliday = state.holiday;
        const newHoliday = state.newHoliday;

        const originHolidayList = [];
        const newHolidayList = [];
        newHoliday.map(items => {
            const holiday = items.holiday;
            newHolidayList.push(holiday);
        });

        originHoliday.map(items => {
            const holiday = items.holiday;
            originHolidayList.push(holiday);
        });

        //휴일 삭제
        const removeTargetList = [];
        originHolidayList.map(holiday => {
            // newHolidayList안에 originHoliday의 특정 일자가 없다.
            if (newHolidayList.indexOf(holiday) === -1) {
                removeTargetList.push(holiday);
            }
        });

        const removeTargetInfoDataList = [];
        originHoliday.map(data => {
            const holiday = data.holiday;
            if (removeTargetList.includes(holiday)) {
                removeTargetInfoDataList.push(data);
            }
        })

        const removeList = removeTargetInfoDataList.map((day) => {
            return axios.delete(`/community/reservation/item/${day.itemId}/holiday/${day.id}`)
        });
        axios
            .all(removeList)
            .then((response) => {
            })
            .catch((error) => {
                result = false;
                console.log('error : ' + JSON.stringify(error));
            })

        //휴일 등록
        let insertHolidayBody = {};
        const insertList = newHolidayList.map(newHoliday => {
            if (originHolidayList.indexOf(newHoliday) === -1) {
                insertHolidayBody = {
                    "holiday": newHoliday,
                }
                return axios.post(`/community/reservation/item/${state.itemId}/holiday`, insertHolidayBody)
            }
        });
        axios
            .all(insertList)
            .then((response) => {
            })
            .catch((error) => {
                result = false;
                console.log('error : ' + JSON.stringify(error));
            })

        cb(result);
    },

    //커뮤니티 - 예약 삭제
    removeReservation(items, callback) {
        const list = items.map(code => {
            return axios.delete(`/community/reservation/item/${code.itemId}`)
        });
        axios
            .all(list)
            .then((response) => {
                callback(true);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    reservationCount(codes, siteCode, reservationDate) {
        const apis = codes.map(item => {
            return axios.get(`/community/reservation/group/${item}/reservationCount?siteCode=${siteCode}&reservationDate=${reservationDate}`);
        })

        return axios.all(apis)
            .then(axios.spread(function (...args) {
                // Both requests are now complete
                return args.map((item, index) => {
                    return {
                        classification: codes[index],
                        ...item.data
                    }
                });
            }))

    },

    //커뮤니티 - 주민투표 목록 검색
    getVoteList(pageState, callback) {
        let searchCondition = "";
        searchCondition += pageState.searchCategory1 ? "&customer.code=" + pageState.searchCategory1 : '';
        searchCondition += pageState.searchCategory2 ? "&site.code=" + pageState.searchCategory2 : '';
        searchCondition += pageState.searchCategory3 ? "&status=" + pageState.searchCategory3 : '';
        searchCondition += pageState.searchText ? "&title=" + pageState.searchText : '';
        axios
            .get(`/community/vote?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}${searchCondition}`)
            .then((response) => {
                console.log(response)
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    //커뮤니티 - 주민투표 상세 검색
    getVoteOne(voteId, callback) {
        axios
            .get(`/community/vote/${voteId}`)
            .then((response) => {
                if (response.data.image) {
                    this.getVoteImg(response.data.image, (response2) => {
                        if (response2.status === 200) {
                            const imageDataUrl = response2.data;
                            const resData = {
                                data: {
                                    ...response.data,
                                    imageDataUrl: imageDataUrl,
                                },
                                status: 200,
                            }
                            callback(resData);
                        }
                    });
                } else {
                    callback(response);
                }
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    //커뮤니티 - 주민투표 상세 검색
    getVoteOneNoImage(voteId, callback) {
        axios
            .get(`/community/vote/${voteId}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    getVoteResult(voteId, callback) {
        axios
            .get(`/community/vote/${voteId}/result`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    getVoteCountList(voteId, callback) {
        axios
            .get(`/community/vote/${voteId}/count`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    getVoteAgendaList(voteId, callback) {
        axios
            .get(`/community/vote/${voteId}/agenda`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    getVoteAgendaOne(form, callback) {
        axios
            .get(`/community/vote/${form.voteId}/agenda/${form.agendaId}`)
            .then((response) => {
                if (response.data.image) {
                    this.getVoteImg(response.data.image, (response2) => {
                        if (response2.status === 200) {
                            const imageDataUrl = response2.data;
                            const resData = {
                                data: {
                                    ...response.data,
                                    imageDataUrl: imageDataUrl,
                                },
                                status: 200,
                            }
                            callback(resData);
                        }
                    });
                } else {
                    callback(response);
                }
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    //커뮤니티 - 예약 등록
    addVote(state, callback) {
        const body = {
            "title": state.title,
            "contents": state.contents,
            "customer": {
                "code": state.customer.code,
                "name": state.customer.name,
            },
            "site": {
                "code": state.site.code,
                "name": state.site.name,
            },
            "classification": state.classification,
            "dong": state.dong ? state.dong : "ALL",
            "voteStartDate": state.voteStartDate,
            "voteEndDate": state.voteEndDate,
            "startDate": state.startDate,
            "image": [],
            "publicYn": state.publicYn
        }
        if (state.imageDataUrl !== '') {
            this.voteImgUpload(state, (response) => {
                if (response.status === 200 || response.status === 201) {
                    const imageId = response.data.imageId;
                    body.image = [imageId];
                    axios
                        .post(`/community/vote`, body)
                        .then((response) => {
                            if (response.status === 200 || response.status === 201) {
                                state.voteId = response.data.voteId;
                                this.updateRegistRemoveVoteAgenda(state, (cb) => {
                                    if (cb) {
                                        callback(response);
                                    }
                                });
                            }
                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        })
                } else if (response === 'error') {
                    body.image = [''];
                    axios
                        .post(`/community/vote`, body)
                        .then((response) => {
                            if (response.status === 200 || response.status === 201) {
                                state.voteId = response.data.voteId;
                                this.updateRegistRemoveVoteAgenda(state, (cb) => {
                                    if (cb) {
                                        callback(response);
                                    }
                                });
                            }
                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        })
                }
            });
        } else {
            axios
                .post(`/community/vote`, body)
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        state.voteId = response.data.voteId;
                        this.updateRegistRemoveVoteAgenda(state, (cb) => {
                            if (cb) {
                                callback(response);
                            }
                        });
                    }
                })
                .catch((error) => {
                    console.log('error : ' + JSON.stringify(error));
                })
        }
    },

    //커뮤니티 - 예약 수정
    updateVote(state, callback) {
        const body = {
            "title": state.title,
            "contents": state.contents,
            "customer": {
                "code": state.customer.code,
                "name": state.customer.name,
            },
            "site": {
                "code": state.site.code,
                "name": state.site.name,
            },
            "classification": state.classification,
            "dong": state.dong ? state.dong : "ALL",
            "voteStartDate": state.voteStartDate,
            "voteEndDate": state.voteEndDate,
            "startDate": state.startDate,
            "image": state.image ? state.image : [],
            "publicYn": state.publicYn
        }
        if (state.imageDataUrl !== '') {
            this.voteImgUpload(state, (response) => {
                if (response.status === 200) {
                    const imageId = response.data.imageId;
                    body.image = [imageId];
                    axios
                        .put(`/community/vote/${state.voteId}`, body)
                        .then((response) => {
                            if (response.status === 200) {
                                this.updateRegistRemoveVoteAgenda(state, (cb) => {
                                    if (cb) {
                                        callback(response);
                                    }
                                });
                            }

                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        })
                } else if (response === 'error') {
                    body.image = [''];
                    axios
                        .put(`/community/vote/${state.voteId}`, body)
                        .then((response) => {
                            if (response.status === 200) {
                                this.updateRegistRemoveVoteAgenda(state, (cb) => {
                                    if (cb) {
                                        callback(response);
                                    }
                                });
                            }

                        })
                        .catch((error) => {
                            console.log('error : ' + JSON.stringify(error));
                        })
                }
            });
        } else {
            axios
                .put(`/community/vote/${state.voteId}`, body)
                .then((response) => {
                    if (response.status === 200) {
                        this.updateRegistRemoveVoteAgenda(state, (cb) => {
                            if (cb) {
                                callback(response);
                            }
                        });
                    }
                })
                .catch((error) => {
                    console.log('error : ' + JSON.stringify(error));
                })
        }
    },

    // 커뮤니티 - 투표 이미지 가져오기
    getVoteImg(image, callback) {
        axios
            .get(`/community/vote/image/view/${image}`, {
                responseType: 'arraybuffer'
            })
            .then((response) => {
                response.data = imageEncode(response.data);
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 커뮤니티 - 투표 이미지업로드
    voteImgUpload(formData, callback) {
        let blob = dataURLtoBlob(formData.imageDataUrl);
        let form = new FormData()
        form.append('image', blob);

        axios
            .post(`/community/vote/image`, form)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
                if (error.name === 'Error') {
                    callback('error');
                }
            })
    },

    //커뮤니티 - 투표 안건 수정, 삭제, 등록
    updateRegistRemoveVoteAgenda(state, cb) {
        let result = true;

        //안건 삭제
        const removeAgendaList = state.removeAgendaList;
        const removeList = removeAgendaList.map(agendaId => {
            return axios.delete(`/community/vote/${state.voteId}/agenda/${agendaId}`)
        });
        axios
            .all(removeList)
            .then((response) => {
            })
            .catch((error) => {
                result = false;
                console.log('error : ' + JSON.stringify(error));
            })

        //안건 등록
        const newAgenda = state.newAgenda;
        const insertList = newAgenda.map((agenda, idx) => {
            const voteNum = idx + 1;
            agenda.voteNum = voteNum;
            if (!agenda.agendaId) {
                if (agenda.imageDataUrl) {
                    this.voteImgUpload(agenda, (response) => {
                        if (response.status === 200) {
                            const imageId = response.data.imageId;
                            const insertBody = {
                                "title": agenda.title,
                                "contents": agenda.contents,
                                "image": [imageId],
                                "voteNum": agenda.voteNum,
                            }

                            return axios.post(`/community/vote/${state.voteId}/agenda`, insertBody);
                        } else if (response === 'error') {
                            const insertBody = {
                                "title": agenda.title,
                                "contents": agenda.contents,
                                "image": [''],
                                "voteNum": agenda.voteNum,
                            }

                            return axios.post(`/community/vote/${state.voteId}/agenda`, insertBody);
                        }
                    });
                } else {
                    const insertBody = {
                        "title": agenda.title,
                        "contents": agenda.contents,
                        "image": agenda.image ? agenda.image : [],
                        "voteNum": agenda.voteNum,
                    }
                    return axios.post(`/community/vote/${state.voteId}/agenda`, insertBody);
                }
            }
        });
        axios
            .all(insertList)
            .then((response) => {
            })
            .catch((error) => {
                result = false;
            })

        const updateList = newAgenda.map((agenda, idx) => {
            if (agenda.agendaId) {
                if (agenda.imageDataUrl) {
                    this.voteImgUpload(agenda, (response) => {
                        if (response.status === 200) {
                            const imageId = response.data.imageId;
                            const updateBody = {
                                "title": agenda.title,
                                "contents": agenda.contents,
                                "image": [imageId],
                                "voteNum": agenda.voteNum
                            }

                            return axios.put(`/community/vote/${state.voteId}/agenda/${agenda.agendaId}`, updateBody);
                        } else if (response === 'error') {
                            const updateBody = {
                                "title": agenda.title,
                                "contents": agenda.contents,
                                "image": [''],
                                "voteNum": agenda.voteNum
                            }

                            return axios.put(`/community/vote/${state.voteId}/agenda/${agenda.agendaId}`, updateBody);
                        }
                    });
                } else {
                    const updateBody = {
                        "title": agenda.title,
                        "contents": agenda.contents,
                        "image": agenda.image ? agenda.image : [],
                        "voteNum": agenda.voteNum
                    }

                    return axios.put(`/community/vote/${state.voteId}/agenda/${agenda.agendaId}`, updateBody);
                }
            }
        });
        axios
            .all(updateList)
            .then((response) => {
            })
            .catch((error) => {
                result = false;
            })

        cb(result);
    },

    //커뮤니티 - 투표 삭제
    removeVote(items, callback) {
        const list = items.map(code => {
            return axios.delete(`/community/vote/${code.voteId}`)
        });

        axios
            .all(list)
            .then((response) => {
                callback(true);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    getVoteCount(siteCode, callback) {
        axios.get(`/community/vote/count?site.code=${siteCode}`)
            .then(response => {
                callback(response.data);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    cloudnLogin(user, callback) {

        let form = new FormData()
        form.append('phone', user.phone);
        form.append('pwd', user.pwd);

        axios
            .post(store.cloudn_api + `/login`, form)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
                if (error.name === 'Error') {
                    callback('error');
                }
            })
    },
    getCloudnDevices(request, callback) {

        let form = new FormData()
        form.append('salt', request.salt);

        axios
            .post(store.cloudn_api + `/device/list`, form)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
                if (error.name === 'Error') {
                    callback('error');
                }
            })
    },

    //방문차량 관리 - 방문차량 목록 조회
    getVisitingcarsList(pageState, callback) {
        let searchCondition = "";
        searchCondition += pageState.searchCategory1 ? "&customer.code=" + pageState.searchCategory1 : '';
        searchCondition += pageState.searchCategory2 ? "&site.code=" + pageState.searchCategory2 : '';
        searchCondition += pageState.searchStartDate ? "&startDate=" + pageState.searchStartDate : '';
        searchCondition += pageState.searchEndDate ? "&endDate=" + pageState.searchEndDate : '';
        searchCondition += pageState.searchText ? "&carNumber=" + pageState.searchText : '';

        console.log(pageState);
        axios
            .get(`/community/visitingcars?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}${searchCondition}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },


    // 장소 - 현장 장소 추가
    addPlace(state, callback) {
        const body = {
            ...state
        }

        axios
            .post(`/places`, body)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 장소 - 현장 장소 추가
    getPlace(params, callback) {
        const queryString = params.username ? `?username=${params.username}` : '';
        axios
            .get(`/places` + queryString)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    addDeviceByODP(state, callback) {
        const body = {
            ...state
        }

        axios
            .post(store.odp_api + `/indoor/device`, body)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
                if (error.name === 'Error') {
                    callback('error');
                }
            })
    },

    // 디바이스 카운트 조회 ( 유저 )
    getUserDeviceCounts(arrayUser, callback) {
        let users = arrayUser.join(",");
        axios
            .get(`/devices/users/counts?usernames=${users}`)
            .then((response) => {
                callback(response.data);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })

    },

    //현장별 홈넷 연동 유저 수 카운트
    getHomeNetUserCounts(siteCode, callback) {
        let searchCondition = "";
        searchCondition += siteCode ? "?siteCode=" + siteCode : '';
        axios
            .get(`/homenets/sites/users/counts${searchCondition}`)
            .then((response) => {
                callback(response.data);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })

    },


    getHomeNetHouseCounts(siteCode, callback) {
        let searchCondition = "";
        searchCondition += siteCode ? "?siteCode=" + siteCode : '';
        axios
            .get(`/homenets/sites/users/place/counts${searchCondition}`)
            .then((response) => {
                callback(response.data);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })

    },

    async getHomeNetUserCountsAsync(siteCode) {
        let searchCondition = "";
        searchCondition += siteCode ? "?siteCode=" + siteCode : '';
        try {

            return await axios
                .get(`/homenets/sites/users/counts${searchCondition}`);
        } catch (error) {
            console.error(error)
        }
    },

    getHomeNetDeviceCounts(siteCode, callback) {
        let searchCondition = "";
        searchCondition += siteCode ? "?siteCode=" + siteCode : '';
        axios
            .get(`/homenets/sites/devices/counts${searchCondition}`)
            .then((response) => {
                callback(response.data);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    getHomeNetDeviceCountsAsync(siteCode) {
        let searchCondition = "";
        searchCondition += siteCode ? "?siteCode=" + siteCode : '';
        try {
            return axios
                .get(`/homenets/sites/devices/counts${searchCondition}`);

        } catch (error) {
            console.error(error)
        }
    },

    getTelecomCountsAsync() {
        try {
            return axios
                .get(`/telecom/dashboard/connection/count`);

        } catch (error) {
            console.error(error)
        }
    },

    getAppliancesCountsAsync() {
        try {
            return axios
                .get(`/appliances/dashboard/connection/count`);
        } catch (error) {
            console.error(error)
        }
    },

    getAppliancesDevicesCountsAsync() {
        try {
            return axios
                .get(`/appliances/dashboard/devices/count`);
        } catch (error) {
            console.error(error)
        }
    },

    getServiceConnections() {
        try {
            return axios
                .get(`/monitoring/services/connections`);

        } catch (error) {
            console.error(error)
        }
    },

    getManufacturerDeviceDuplication() {
        try {
            return axios
                .get(`/monitoring/services/manufacturers/devices/duplication`);
        } catch (error) {
            console.error(error)
        }
    },

    // 모니터링 - 현장별 유저 현황
    getUserBySiteCountAsync(customerCode) {
        let searchCondition = "";
        searchCondition += customerCode ? "?customerCode=" + customerCode : '';
        try {
            return axios
                .get(`/monitoring/user-by-site/count${searchCondition}`);

        } catch (error) {
            console.error(error)
        }
    },

    // 현장별 동 리스트 조회
    getDongsBySiteAsync(siteCode) {
        let searchCondition = "";
        searchCondition += siteCode ? "?siteCode=" + siteCode : '';
        try {
            return axios
                .get(`/monitoring/site/dong${searchCondition}`);

        } catch (error) {
            console.error(error)
        }
    },

    //  고객사별 유저 디바이스 현황 조회
    getUserDeviceCountsByCustomerAsync(customerCode) {
        let searchCondition = "";
        searchCondition += customerCode ? "?customerCode=" + customerCode : '';
        try {
            return axios
                .get(`/monitoring/user-by-site-device/count${searchCondition}`, {timeout: 15000, retry: 0});

        } catch (error) {
            console.error(error)
        }
    },

    ///고객사, 현장별 구글 사용자 현황 조회
    getGoogleUserCountByCustomerAsync(customerCode){
        try {
            return axios
                .get(`/monitoring/user-by-site-device/count/google?customerCode=${customerCode}`);
        } catch(error){
            console.error(error);
        }
    },

   // 동별 유저 디바이스 현황 조회
    getUserDeviceCountsBySiteAndDongAsync(siteCode, dong) {
        let searchCondition = "";
        searchCondition += siteCode ? "?siteCode=" + siteCode : '';
        searchCondition += dong ? "&dong=" + dong : '';
        return axios
            .get(`/monitoring/user-by-site-device/dong/count${searchCondition}`);
    },


    getUserCountsBySiteAndDongAsync(siteCode, dong) {
        let searchCondition = "";
        searchCondition += siteCode ? "?siteCode=" + siteCode : '';
        searchCondition += dong ? "&dong=" + dong : '';
        try {
            return axios.get(`/monitoring/user-by-site/dong/count${searchCondition}`, {timeout: 1000, retry: 0});
        } catch (error) {
            console.error(error);
        }
    },

    getUserCountsBySiteAndDong(siteCode, dong, callback) {
        let searchCondition = "";
        searchCondition += siteCode ? "?siteCode=" + siteCode : '';
        searchCondition += dong ? "&dong=" + dong : '';
        axios
            .get(`/monitoring/user-by-site/dong/count${searchCondition}`)
            .then((response) => {
                callback(response.data);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    getUserListByPlacement(siteCode, dong, ho) {
        let searchCondition = "";
        searchCondition += siteCode ? "?siteCode=" + siteCode : '';
        searchCondition += dong ? "&dong=" + dong : '';
        searchCondition += ho ? "&ho=" + ho : '';
        try {
            return axios
                .get(`/monitoring/user-by-site/ho/account${searchCondition}`);
        } catch (error) {
            console.error(error)
        }
    },

    getHouseHoldVoiceServiceCountAsync(customerCode, siteCode) {
        let searchCondition = "";
        searchCondition += customerCode ? "?customerCode=" + customerCode : '';
        searchCondition += siteCode ? "&siteCode=" + siteCode : '';
        try {
            return axios
                .get(`/monitoring/user-by-site-device/count/voiceService${searchCondition}`);
        } catch (error) {
            console.error(error)
        }
    },

    getUserDeviceCountsBySiteAsync(customerCode, siteCode) {
        let searchCondition = "";
        searchCondition += customerCode ? "?customerCode=" + customerCode : '';
        searchCondition += siteCode ? "&siteCode=" + siteCode : '';
        try {
            return axios
                .get(`/monitoring/user-by-site-device/count${searchCondition}`);
        } catch (error) {
            console.error(error)
        }
    },

    getDuplicatedPlaceCountsBySiteAsync(customerCode) {
        let searchCondition = "";
        searchCondition += customerCode ? "?customerCode=" + customerCode : '';
        try {
            return axios
                .get(`/monitoring/duplicated-place/site-list${searchCondition}`);

        } catch (error) {
            console.error(error)
        }
    },

    getDuplicatedPlaceDetailBySiteAsync(siteCode) {
        let searchCondition = "";
        searchCondition += siteCode ? "?siteCode=" + siteCode : '';
        try {
            return axios
                .get(`/monitoring/duplicated-place/detail${searchCondition}`);

        } catch (error) {
            console.error(error)
        }
    },

    //placeMaster 조회
    getHoListBySiteAndDongAsync(siteCode, dong) {
        let searchCondition = "";
        searchCondition += siteCode ? "?siteCode=" + siteCode : '';
        searchCondition += dong ? "&dong=" + dong : '';
        try {
            return axios
                .get(`monitoring/site/dong/ho${searchCondition}`);
        } catch (error) {
            console.error(error)
        }
    },

    //placeMaster checklist 조회
    getHoListForCheckListBySiteAndDongAsync(siteCode, dong, ho, note) {
        let searchCondition = "";
        searchCondition += siteCode ? "?site.code=" + siteCode : '';
        searchCondition += dong ? "&dong=" + dong : '';
        searchCondition += ho ? "&ho=" + ho : '';
        searchCondition += note ? "&note=" + note : '';
        try {
            return axios
                .get(`places/check/list${searchCondition}`);
        } catch (error) {
            console.error(error)
        }
    },

    //checklist 삭제
    deleteCheckListById(placeCheckId) {
        try {
            return axios.delete(`/places/check/list/${placeCheckId}`)
        } catch (error) {
            console.error(error)
        }
    },

    getHomenetAuthorizationStatusBySiteAsync(siteCode) {
        try {
            return axios
                .get(`/monitoring/homenets/authorizations/sites/${siteCode}`);
        } catch (error) {
            console.error(error)
        }
    },

    getHomenetAuthorizationStatusListAsync(siteCode, dong, ho) {
        let searchCondition = "";
        searchCondition += siteCode ? "?siteCode=" + siteCode : '';
        searchCondition += dong ? "&dong=" + dong : '';
        searchCondition += ho ? "&ho=" + ho : '';
        try {
            return axios
                .get(`/monitoring/homenets/authorizations${searchCondition}`)
                .catch((error) => {
                    console.log('error : ' + JSON.stringify(error));
                })
        } catch (error) {
            console.error(error)
        }
    },

    getCallbackLogAsync() {
        try {
            return axios
                .get(`/monitoring/homenets/device-request-log`);

        } catch (error) {
            console.error(error)
        }
    },

    // 세대 디바이스 및 상태 조회 ( 여러계정 중 랜덤 1개 )
    getDeviceStatusByDongHoAsync(siteCode, dong, ho) {
        let searchCondition = "";
        searchCondition += siteCode ? "?siteCode=" + siteCode : '';
        searchCondition += dong ? "&dong=" + dong : '';
        searchCondition += ho ? "&ho=" + ho : '';
        try {
            return axios
                .get(`/monitoring/device-status-site/household${searchCondition}`)

        } catch (error) {
            console.error(error)
        }
    },

    // 세대 디바이스 및 상태 조회 (콜백 버전)
    getDeviceStatusByDongHoCallback(siteCode, dong, ho, callback) {
        let searchCondition = "";
        searchCondition += siteCode ? "?siteCode=" + siteCode : '';
        searchCondition += dong ? "&dong=" + dong : '';
        searchCondition += ho ? "&ho=" + ho : '';
        axios
            .get(`/monitoring/device-status-site/household${searchCondition}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 세대 디바이스 및 상태 조회 ( 유저별 )
    getDeviceStatusByUserAsync(username) {
        let searchCondition = "";
        searchCondition += username ? "?username=" + username : '';
        try {
            return axios
                .get(`/monitoring/device-status-site/user${searchCondition}`)

        } catch (error) {
            console.error(error)
        }
    },

    //개별 디바이스 상태조회
    getDeviceStatusListByUserAndDeviceId(items, username, callback) {
        const list = items.map(code => {
            return homenetAxios.get(`/homenets/admin/devices/${code.deviceId}?username=${username}`)
        });

        axios
            .all(list)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },


    addAutomationPost(pageState) {
        const body = {
            siteCode: pageState.site.code,
            dong: pageState.dong,
            ho: pageState.ho,
            onlyTemporary: pageState.onlyTemporary,
            time: pageState.startTime,
            name: pageState.name,
            description: pageState.description,
            deviceModel: pageState.deviceModel,
            propertyCode: pageState.attributeCode,
            propertyName: pageState.attributeName,
            value: pageState.attributeValue,
            enable: true
        }
        try {
            return axios
                .post(`/automation/admin/batch`, body)
        } catch (error) {
            console.error(error)
        }
    },

    getAutomationTest(siteCode, dong, ho, onlyTemporary, name) {
        let searchCondition = "";
        searchCondition += siteCode ? "?siteCode=" + siteCode : '';
        searchCondition += dong ? "&dong=" + dong : '';
        searchCondition += ho ? "&ho=" + ho : '';
        searchCondition += onlyTemporary ? "&onlyTemporary=" + onlyTemporary : '';
        searchCondition += name ? "&name=" + name : '';
        try {
            return axios
                .get(`/automation/admin/batch${searchCondition}`)
        } catch (error) {
            console.error(error)
        }
    },

    postExecutorAutomation(automationId) {
        try {
            return axios.post(`/automation/${automationId}/executor`)
        } catch (error) {
            console.error(error)
        }
    },

    //자동화 일괄 삭제
    removeAutomation(items) {
        const body = {
            automationIds: items
        }
        try {
            return axios.delete(`/automation/admin/batch`, {data: body})
        } catch (error) {
            console.log('error : ' + JSON.stringify(error));

        }
    },

    //자동화 일괄 삭제
    updateAutomation(items, automation) {
        const body = {
            automationIds: items,
            automation: automation
        }
        try {
            return axios.put(`/automation/admin/batch`, body)
        } catch (error) {
            console.log('error : ' + JSON.stringify(error));

        }
    },

    // 장비 제어
    deviceControl(state, callback) {
        const body = {
            operations: state.operations,
        }
        const admin = "admin"
        axios
            .put(`/devices/${state.deviceId}/statuses?requestType=${admin}`, body)
            .then((response) => {
                callback(response);
            })
            .catch(() => {
            })
    },

    //장비 상태 조회
    getDevicesStatus(username, callback){

        axios
            .get(`/homenets/admin/devices/status/refresh?username=${username}`)
            .then((response)=>{
                console.log(response);
                callback(response);
            })
            .catch((error)=>{
               console.log(error);
            });
    },

    // 전수 테스트 기록 저장
    saveTestRecord(state) {
        const body = {
            code: state.code,
            dong: state.dong,
            ho: state.ho,
            check: state.check,
            performer: state.performer,
            note: state.note,
            devices: state.devices,
        }
        console.log(body)
        try {
            return axios.post(`/places/check/list`, body)
        } catch (error) {
            console.log('error : ' + JSON.stringify(error));

        }
    },

    async HomenetAuthorization(state) {
        const body = {
            homenetUsername: state.homenetUsername,
            homenetPassword: state.homenetPassword,
            siteName: state.site.name,
            regionCode: state.region.code,
            siteCode: state.site.code,
            dong: state.dong,
            ho: state.ho,
        }
        const config = {
            headers: {
                username: state.username
            }
        }
        try {
            return await axios.post(`/homenets/admin/login`, body, config)
        } catch (error) {
            console.log('error : ' + JSON.stringify(error));
            alert('홈넷 인증이 불가능한 세대입니다.')
        }
    },

    //홈넷 해지 관리자용
    HomenetDisconnect(username) {
        const config = {
            headers: {
                username: username
            }
        }
        axios
            .delete(`/homenets/admin/disconnect`, config)
            .then(async (response) => {
                return response;
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
                if (error.name === 'Error') {
                    return error;
                }
            })
    },

    // 사용자 - 유저 삭제
    HomenetDisconnectUserList(items, callback) {
        const list = items.map(code => {
            const config = {
                headers: {
                    username: code.username
                }
            }
            return  axios.delete(`/homenets/admin/disconnect`, config)
        });

        axios
            .all(list)
            .then((response) => {
                callback(true);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    RequestLogDeviceType(state, callback) {
        let searchCondition = "";
        searchCondition += state.searchCategory2 ? "?siteCode=" + state.searchCategory2 : '';
        searchCondition += state.searchStartDate ? "&start=" + state.searchStartDate : '';
        searchCondition += state.searchEndDate ? "&end=" + state.searchEndDate : '';
        searchCondition += state.searchCategory3 ? "&requestType=" + state.searchCategory3 : '';
        searchCondition += state.searchText ? "&hour=" + state.searchText : '';
        axios
            .get(`/monitoring/log/request/aggregate/device-type${searchCondition}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
                if (error.name === 'Error') {
                    return error;
                }
            })
    },

    RequestLogRequestType(state, callback) {
        let searchCondition = "";
        searchCondition += state.searchCategory2 ? "?siteCode=" + state.searchCategory2 : '';
        searchCondition += state.searchStartDate ? "&start=" + state.searchStartDate : '';
        searchCondition += state.searchEndDate ? "&end=" + state.searchEndDate : '';
        searchCondition += state.searchCategory3 ? "&deviceType=" + state.searchCategory3 : '';
        searchCondition += state.searchText ? "&hour=" + state.searchText : '';
        axios
            .get(`/monitoring/log/request/aggregate/request-type${searchCondition}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
                if (error.name === 'Error') {
                    return error;
                }
            })
    },

    RequestLogByHour(state, callback) {
        let searchCondition = "";
        searchCondition += state.searchCategory2 ? "?siteCode=" + state.searchCategory2 : '';
        searchCondition += state.searchStartDate ? "&start=" + state.searchStartDate : '';
        searchCondition += state.searchEndDate ? "&end=" + state.searchEndDate : '';
        searchCondition += state.searchCategory3 ? "&deviceType=" + state.searchCategory3 : '';
        searchCondition += state.searchCategory4 ? "&requestType=" + state.searchCategory4 : '';
        axios
            .get(`/monitoring/log/request/aggregate/hour${searchCondition}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
                if (error.name === 'Error') {
                    return error;
                }
            })
    },

    RequestLogByHouse(state, callback) {
        let searchCondition = "";
        searchCondition += state.searchCategory2 ? "?siteCode=" + state.searchCategory2 : '';
        searchCondition += state.searchStartDate ? "&start=" + state.searchStartDate : '';
        searchCondition += state.searchEndDate ? "&end=" + state.searchEndDate : '';
        searchCondition += state.searchCategory3 ? "&deviceType=" + state.searchCategory3 : '';
        searchCondition += state.searchCategory4 ? "&requestType=" + state.searchCategory4 : '';
        searchCondition += state.searchText ? "&hour=" + state.searchText : '';

        axios
            .get(`/monitoring/log/request/aggregate/house${searchCondition}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
                if (error.name === 'Error') {
                    return error;
                }
            })
    },

    RequestLogByGroupByModel(state, callback) {
        let searchCondition = "";
        searchCondition += state.searchCategory1 ? "?customerCode=" + state.searchCategory1 : '';
        searchCondition += state.searchCategory2 ? "&siteCode=" + state.searchCategory2 : '';
        searchCondition += state.searchStartDate ? "&start=" + state.searchStartDate : '';
        searchCondition += state.searchEndDate ? "&end=" + state.searchEndDate : '';
        searchCondition += state.searchCategory3 ? "&requestType=" + state.searchCategory3 : '';
        searchCondition += state.searchText ? "&hour=" + state.searchText : '';

        axios
            .get(`/monitoring/log/request/aggregate/group-by-site-model${searchCondition}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
                if (error.name === 'Error') {
                    return error;
                }
            })
    },

    RequestLogByBuilding(state, callback) {
        let searchCondition = "";
        searchCondition += state.searchCategory2 ? "?siteCode=" + state.searchCategory2 : '';
        searchCondition += state.searchStartDate ? "&start=" + state.searchStartDate : '';
        searchCondition += state.searchEndDate ? "&end=" + state.searchEndDate : '';
        searchCondition += state.searchCategory3 ? "&deviceType=" + state.searchCategory3 : '';
        searchCondition += state.searchCategory4 ? "&requestType=" + state.searchCategory4 : '';
        searchCondition += state.searchText ? "&hour=" + state.searchText : '';

        axios
            .get(`/monitoring/log/request/aggregate/building${searchCondition}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
                if (error.name === 'Error') {
                    return error;
                }
            })
    },

    // 장소 - 현장 전체 조회
    getSitesMngitemList(pageState, callback) {
        let searchCondition = "";
        searchCondition += pageState.customerCode ? "&customer.code=" + pageState.customerCode : '';
        searchCondition += pageState.searchText ? "&site.name=" + pageState.searchText : '';

        axios
            .get(`/sites/mngitem?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}${searchCondition}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },


    // 장소 - 현장 상세 조회(code)
    getSiteMngitemOne(code, callback) {
        axios
            .get(`/sites/mngitem/${code}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    getDuplicateMngitem(code, callback) {
        axios
            .get(`/sites/mngitem?site.code=${code}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
                callback(error.response);
            });
    },

    // 장소 - 현장 삭제
    removeSitesMngitem(items, callback) {
        const list = items.map(code => {
            return axios.delete(`/sites/mngitem/${code}`)
        });

        axios
            .all(list)
            .then((response) => {
                console.log(response)
                callback(true);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },


    // 장소 - 현장 추가
    addSiteMngitem(state, callback) {
        const body = {
            ...state,
        }
        axios
            .post(`/sites/mngitem`, body)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 장소 - 현장 수정
    updateSiteMngitem(state, callback) {
        const body = {
            ...state
        }
        axios
            .put(`/sites/mngitem`, body)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    getBridgeSettingType(state, callback) {
        let searchCondition = "";
        searchCondition += state.deviceType ? "&typeCode=" + state.deviceType : '';

        axios
            .get(`/bridge/settings/type?page=0&sizePerPage=${state.rowPerPage}${searchCondition}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    getTelecomSettingType(state, callback) {
        console.log(state)
        const body = {
            ...state
        };

        axios
            .get(`/telecom/settings/type?page=0&sizePerPage=${state.rowPerPage}`, body)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    getTelecomSettingProperty(state, callback) {
        let searchCondition = "";
        searchCondition += state.deviceType ? "&deviceType=" + state.deviceType : '';
        searchCondition += state.extensionService ? "&extensionService=" + state.extensionService : '';
        searchCondition += state.modelCode ? "&modelCode=" + state.modelCode : '';
        axios
            .get(`/telecom/settings/property?page=0&sizePerPage=99999${searchCondition}`)
            .then((response) => {
                console.log(response)
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    getBridgeSettingProperty(state, callback) {
        console.log(state);
        let searchCondition = "";
        searchCondition += state.deviceType ? "&deviceType=" + state.deviceType : '';
        searchCondition += state.extensionService ? "&extensionService=" + state.extensionService : '';

        axios
            .get(`/bridge/settings/property/?page=0&sizePerPage=99999${searchCondition}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    getApplianceSettingType(state, callback) {
        let searchCondition = "";
        searchCondition += state.type ? "&type=" + state.type : '';
        searchCondition += state.appliances ? "&appliances=" + state.appliances : '';

        axios
            .get(`/appliances/settings/model?page=${state.currentPage}&sizePerPage=${state.rowPerPage}${searchCondition}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    getApplianceDeviceMapping(state, callback) {
        let searchCondition = "";
        searchCondition += state.type ? "&deviceType=" + state.type : '';
        searchCondition += state.appliances ? "&appliances=" + state.appliances : '';
        axios
            .get(`/appliances/settings/devices?page=0&sizePerPage=99999${searchCondition}`)
            .then((response) => {
                console.log(response)
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    getAppServiceList(state, callback){
        let searchCondition = "";
        searchCondition += state.searchCategory1 ? "&customer.code=" + state.searchCategory1 : '';
        searchCondition += state.customer ? "&customer.code=" + state.customer.code : '';
        searchCondition += state.searchCategory2  ? "&site.code=" + state.searchCategory2 : '';
        searchCondition += state.site || state.site ? "&site.code=" + state.site.code : '';
        searchCondition += state.searchText ? "&site.name=" + state.searchText : '';

        axios
            .get(`/sites/service?page=${state.currentPage}&sizePerPage=${state.rowPerPage}${searchCondition}`)
            .then((response)=>{
                callback(response);
            })
            .catch((error)=>{
                console.log('error : ' + JSON.stringify(error));
            })
    },

    //현장 서비스 관리 상세 조회
    getAppServiceById(id, callback){
        axios
            .get(`/sites/service/${id}`)
            .then((response)=>{
                callback(response);
            })
            .catch((error)=>{
                console.log('error : ' + JSON.stringify(error));
            })
    },

    //현장 앱 서비스 관리 생성
    addAppService(form, callback){
        const body = {
            customer : form.customer,
            site : form.site,
            mainService : [],
            searchService : [],
            communityService : [],
            settings : []
        }
        axios
            .post(`/sites/service`, body)
            .then((response) => {
                console.log(response);
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },


    // 장소 - 현장 수정
    updateAppService(state, callback) {
        const body = {
            ...state
        }
        axios
            .put(`/sites/service`, body)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },


    // 현장 앱 서비스관리 삭제
    deleteAppServiceList(items, callback){
        const list = items.map(appServiceId => {
                return axios.delete(`sites/service/${appServiceId}`)
            });
            axios
                .all(list)
                .then((response) => {
                    callback(true);
                })
                .catch((error) => {
                    console.log('error : ' + JSON.stringify(error));
                });
        },

    // 현장 앱 서비스관리 삭제
    deleteAppService(id, callback){
        axios
            .delete(`sites/service/${id}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    getFreepassUserList(state, callback) {
        let searchCondition = "";
        searchCondition += state.searchCategory1 ? "&customer.Code=" + state.searchCategory1 : '';
        searchCondition += state.searchCategory2 ? "&site.Code=" + state.searchCategory2 : '';
        searchCondition += state.username ? "&username=" + state.username : '';
        searchCondition += state.freePassKey ? "&freePassKey=" + state.freePassKey : '';
        searchCondition += state.freePassDong ? "&freePassDong=" + state.freePassDong : '';
        searchCondition += state.freePassHo ? "&freePassHo=" + state.freePassHo : '';
        console.log(searchCondition)
        axios
            .get(`/freepass/service/admin?page=${state.currentPage}&sizePerPage=${state.rowPerPage}${searchCondition}`)
            .then((response) => {
                console.log(response)
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    logoutFreePassAuth(username, callback) {
        axios
            .delete(`/freepass/service/logout/${username}`)
            .then((response)=>{
                callback(response);
            })
            .catch((error)=>{
                console.log('error :' + JSON.stringify(error));
            });
    },


    //커뮤니티 - 공지사항 삭제
    logoutFreePassAuthList(items, callback) {
        const list = items.map(code => {
            return axios.delete(`/freepass/service/logout/${code.username}`)
        });

        axios
            .all(list)
            .then((response) => {
                callback(true);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    //홈넷사 로그인 체크
    checkHomenetsLogin(username, callback) {
        const config = {
            headers : {
                username : username
            }
        }
        axios
            .post(`/homenets/admin/login/check`,{} ,config)
            .then((response)=>{
                if(response.data.message === 'Unauthorized') alert('제어 후 다시 눌러 주시기 바랍니다.');
                callback(response);
            })
            .catch((error)=>{
                console.log('error :' + JSON.stringify(error));
            });
    },

    //텔레콤 인증 사용자 조회
    getTelecomUserList(pageState, callback) {

        let searchCondition = "";
        searchCondition += pageState.searchText ? "&username=" + pageState.searchText : '';
        searchCondition += pageState.searchCategory2 ? "&siteCode=" + pageState.searchCategory2 : '';
        searchCondition += pageState.searchText2 ? "&dong=" + pageState.searchText2 : '';
        searchCondition += pageState.searchText3 ? "&ho=" + pageState.searchText3 : '';
        searchCondition += pageState.ktConnection ? "&ktConnection=true" : '';
        searchCondition += pageState.sktConnection ? "&sktConnection=true" : '';
        searchCondition += pageState.samsungConnection ? "&samsungConnection=true" : '';
        searchCondition += pageState.lgUPlusConnection ? "&lgUPlusConnection=true" : '';

        axios
            .get(`/telecom/settings/userplace?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}${searchCondition}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    //텔레콤 유저 디바이스 목록 조회
    getTelecomUserDeviceList(pageState, callback) {

        let searchCondition = "";
        searchCondition += pageState.username ? "&username=" + pageState.username : '';
        searchCondition += pageState.extensionService ? "&extensionService=" + pageState.extensionService : '';

        axios
            .get(`/telecom/settings/userdevice?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}${searchCondition}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    //텔레콤 인증 사용자 조회
    getLgApplianceUserList(pageState, callback) {

        let searchCondition = "";
        searchCondition += pageState.searchText ? "&username=" + pageState.searchText : '';
        searchCondition += pageState.searchCategory2 ? "&siteCode=" + pageState.searchCategory2 : '';

        axios
            .get(`/appliances/lg/auth/list?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}${searchCondition}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    //텔레콤 유저 디바이스 목록 조회
    getLgApplianceUserDeviceList(pageState, callback) {

        let searchCondition = "";
        searchCondition += pageState.username ? "&username=" + pageState.username : '';
        searchCondition += pageState.searchText ? "&deviceId=" + pageState.searchText : '';

        axios
            .get(`/appliances/settings/userdevice?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}${searchCondition}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 프리패스 - 현장 목록 조회
    getFreePasssitesList(pageState, callback) {
        let searchCondition = "";
        searchCondition += pageState.siteCode ? "&site.Code=" + pageState.siteCode : '';
        searchCondition += pageState.searchText ? "&freePassEmail=" + pageState.searchText : '';
        axios
            .get(`/freepass/master?page=${pageState.currentPage-1}&sizePerPage=${pageState.rowPerPage}${searchCondition}`)
            .then((response) => {
                console.log(response);
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    //프리패스 - 현장 추가
    addFreePassSite(state, callback) {
        const body = {
            "customer": {
                "code": state.customer.code,
                "name": state.customer.name,
            },
            "site": {
                "code": state.site.code,
                "name": state.site.name,
            },
            "email" : state.freePassEmail,
            "password" : state.freePassEmail,
            "accessToken" : state.freePassAccessToken,
            "message" : state.freePassMessage,
            "autoParking" : state.autoParking
        }
        axios
            .post(`/freepass/master`, body)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 프리패스 - 현장 삭제
    removeFreePassSitesList(state,password, callback) {
        axios
            .delete(`/freepass/master?freePassEmail=${state.freePassEmail}&freePassPassword=${password}&siteCode=${state.site.code}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    //프리패스 - 초대 목록 조회
    getFreePassInviteList(pageState, callback) {
        let placeName = '';
        placeName += pageState.searchText2 ? pageState.searchText2 + '-' : '-';
        placeName += pageState.searchText3 ? pageState.searchText3 : '';

        let searchCondition = "";
        searchCondition += pageState.siteCode ? "&site.Code=" + pageState.siteCode : '';
        searchCondition += pageState.searchText ? "&username=" + pageState.searchText : '';
        if(!pageState.boolWholePeriod){
            searchCondition += pageState.searchStartDate ? "&startDate=" + pageState.searchStartDate : '';
            searchCondition += pageState.searchEndDate ? "&endDate=" + pageState.searchEndDate : '';
        }
        searchCondition += placeName === '-' ? '' : "&place.name=" + placeName;

        axios
            .get(`/freepass/invite/admin?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}${searchCondition}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 프리패스 - 초대 상세 조회
    getFreePassInviteDetail(inviteId, callback){
        axios
            .get(`/freepass/invite/admin/detail/${inviteId}`)
            .then((response)=>{
                callback(response);
            })
            .catch((error)=>{
                console.log('error : ' + JSON.stringify(error));
            });
    },

    //커뮤니티 - 게시판 마스터 삭제
    removeFreePassInviteList(items, callback) {
        const list = items.map(code => {
            return axios.delete(`/freepass/invite/${code.inviteId}`)
        });

        axios
            .all(list)
            .then((response) => {
                callback(true);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    //사용자 방문 차량 삭제
    deleteVisitorCar(state, callback) {
        axios
            .delete(`/freepass/visitor/car/${state.visitorId}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
                callback(error);
            });
    },


    //커뮤니티 - 게시판 마스터 리스트 검색
    getBoardMasterList(pageState, callback) {
        let searchCondition = "";
        searchCondition += pageState.searchText ? "&name=" + pageState.searchText : '';
        axios
            .get(`/community/boardMaster?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}${searchCondition}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    //커뮤니티 - 게시판 마스터 상세 검색
    getBoardMasterOne(code, callback) {
        axios
            .get(`/community/boardMaster/${code}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 커뮤니티 - 게시판 마스터 저장
    addBoardMaster(state, callback) {
        const body = {
            code: state.code,
            name: state.name,
            addProperty1: state.addProperty1,
            addProperty2: state.addProperty2,
        }

        axios
            .post(`/community/boardMaster`, body)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    // 커뮤니티 - 게시판 마스터 수정
    updateBoardMaster(state, callback) {
        const body = {
            name: state.name,
            addProperty1: state.addProperty1,
            addProperty2: state.addProperty2,
        }

        axios
            .put(`/community/boardMaster/${state.code}`, body)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    //커뮤니티 - 게시판 마스터 삭제
    removeBoardMaster(items, callback) {
        const list = items.map(code => {
            return axios.delete(`/community/boardMaster/${code.code}`)
        });

        axios
            .all(list)
            .then((response) => {
                callback(true);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    //커뮤니티 - 게시판 마스터 리스트 검색
    getBoardList(pageState, callback) {
        console.log(pageState);
        let searchCondition = "";
        searchCondition += pageState.category ? "&category=" + pageState.category : '';
        searchCondition += pageState.searchCategory1 ? "&customer.code=" + pageState.searchCategory1 : '';
        searchCondition += pageState.searchCategory2 ? "&site.code=" + pageState.searchCategory2 : '';

        axios
            .get(`/community/board?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}${searchCondition}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            })
    },

    //커뮤니티 -게시판 코드 다중 검색
    getBoardListByCodes(items, callback) {
        const list = items.map(code => {
            return axios.get(`/community/board?category=${code.code}`)
        });

        axios
            .all(list)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },


    // 커뮤니티 - 게시판 목록 조회(타입별)
    getBoardListByCategory(category, callback) {
        axios
            .get(`/community/board?category=${category}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    // 커뮤니티 - 게시판 목록 조회(타입별)
    getBoardListByCategoryAsync(category) {
        axios
            .get(`/community/board?category=${category}`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },


    // 커뮤니티 - 게시판 상세 조회
    async getBoardOneAsync(boardId) {
        try {

            const response = await axios.get(`/community/board/${boardId}`);
            let resData = {
                data : {
                    ...response.data
                },
                status : 200
            }
            if(response.data.image){
                const responseImageUrl = await this.getBoardImg(response.data.image);
                console.log(responseImageUrl)
                if(responseImageUrl.status === 200 ){
                    const imageDataUrl = imageEncode(responseImageUrl.data);
                    resData = {
                        ...resData,
                        data : {
                            ...resData.data,
                            imageDataUrl: imageDataUrl
                        }
                    }
                }
            }
            if(response.data.file){
                const responseFileUrl = await this.getBoardFilePath(response.data.file);
                console.log(responseFileUrl)
                if(responseFileUrl.status === 200 ){
                    resData = {
                        ...resData,
                        data : {
                            ...resData.data,
                            fileDataUrl: responseFileUrl.data.filePath,
                            fileName : responseFileUrl.data.fileName
                        }
                    }
                }
            }
            console.log(resData);
            return resData;
        } catch(err) {
            console.log("Error " , err);
        }
    },

    // 커뮤니티 - 게시판 삭제
    removeBoard(boardId, callback) {
        axios
            .delete(`/community/board/${boardId}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },

    removeBoardList(items, callback) {
        const list = items.map(code => {
            return axios.delete(`/community/board/${code.boardId}`)
        });

        axios
            .all(list)
            .then((response) => {
                callback(true);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
            });
    },


    // 커뮤니티 - 보드 이미지조회
     getBoardImg(image) {
        console.log(image);
        try {
            return axios
                .get(`/community/board/image/view/${image}`, {
                    responseType: 'arraybuffer'
                })
        }catch (err){
            console.log("getBoardImg", err);
        }
    },

    getCommunityImg(classification , image) {
        try {
            return axios
                .get(`/community/${classification}/image/view/${image}`, {
                    responseType: 'arraybuffer'
                })
        }catch (err){
            console.log("getCommunityImg", err);
        }
    },

    async getCommunityFilePath(classification, file) {
        try{
            return axios
                .get(`/community/${classification}/file/${file}`)
        } catch(err){
            console.log("getCommunityFilePath" ,err);
        }
    },

    //커뮤니티 - 파일 Url 조회
    async getBoardFile(file) {
        console.log(file);
        try {
            return axios
                .get(`/community/board/file/view/${file}`, {
                    responseType: 'arraybuffer'
                })
        }catch (err){
            console.log("getBoardImg", err);
        }
    },

    async getBoardFilePath(file) {
        try{
            return axios
                .get(`/community/board/file/${file}`)
        } catch(err){
            console.log("getBoardFilePath" ,err);
        }
    },


    boardImgUpload(formData, callback) {
        let blob = dataURLtoBlob(formData.imageDataUrl);
        let form = new FormData()
        form.append('image', blob);

        axios
            .post(`/community/board/image`, form)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
                if (error.name === 'Error') {
                    callback('error');
                }
            })
    },

    async addBoardAsync(state) {
        let imageData = '';
        let fileData = '';
        if (state.imageDataUrl !== '') {
            imageData = await this.ImgUploadAsync(state.imageDataUrl, 'board');
        }
        if (state.fileData !== '') {
            fileData = await this.FileUploadAsync(state.fileData, 'board');
        }

        async function PostBoard(image, fileData, state) {
            const body = {
                "category": state.category,
                "title": state.title,
                "content": state.content,
                "customer": {
                    "code": state.customer.code,
                    "name": state.customer.name,
                },
                "site": {
                    "code": state.site.code,
                    "name": state.site.name,
                },
                "user": state.user,
                "image": [],
                "file": [],
                "attribute1": state.attribute1,
                "attribute2": state.attribute2,
            }

            if (image.hasOwnProperty("data")) {
                if (image.data.hasOwnProperty("imageId")) {
                    body.image = [image.data.imageId];
                }
            }

            if (fileData.hasOwnProperty("data")) {
                if (fileData.data.hasOwnProperty("fileId")) {
                    body.file = [fileData.data.fileId];
                }
            }

            try {
                return await axios.post(`/community/board`, body);
            } catch (e) {
                console.log(e);
            }
        }

        console.log(imageData, fileData);
        return PostBoard(imageData, fileData, state);
    },

    async updateBoardAsync(state) {
        let imageData = '';
        let fileData = '';

        console.log(state.imageDataUrl, state.fileDataUrl, state)
        if (state.imageDataUrl !== '') {
            imageData = await this.ImgUploadAsync(state.imageDataUrl, 'board');
        }
        if (state.fileData !== '') {
            fileData = await this.FileUploadAsync(state.fileData, 'board');
        }

        async function PutBoard(image, fileData, state) {
            const body = {
                "category": state.category,
                "title": state.title,
                "content": state.content,
                "customer": {
                    "code": state.customer.code,
                    "name": state.customer.name,
                },
                "site": {
                    "code": state.site.code,
                    "name": state.site.name,
                },
                "user": state.user,
                "image": [],
                "file": state.file,
                "attribute1": state.attribute1,
                "attribute2": state.attribute2,
            }

            if (image.hasOwnProperty("data")) {
                if (image.data.hasOwnProperty("imageId")) {
                    body.image = [image.data.imageId];
                }
            }

            if (fileData.hasOwnProperty("data")) {
                if (fileData.data.hasOwnProperty("fileId")) {
                    body.file = [fileData.data.fileId];
                }
            }

            try {
                return await axios.put(`/community/board/${state.boardId}`, body);
            } catch (e) {
                console.log(e);
            }
        }

        console.log(imageData, fileData);
        return PutBoard(imageData, fileData, state);
    },

    async downloadFile(classification, fileId){
        try{
            const response = await axios({
                method: "get",
                url : `/community/${classification}/file/view/${fileId}`,
                responseType : 'blob'
            });
            return response;
        } catch(err){
            console.log(err);
        }
    },

    async getFileData(classification, fileId){
        try{
            return await axios({
                method: "get",
                url: `/community/${classification}/file/${fileId}`,
            });
        } catch(err){
            console.log(err);
        }
    },


    //커뮤니티 - 관리비 현장 조회
    async getApartmentList(pageState){
        let searchCondition = "";
        searchCondition += pageState.searchText ? "&site.name=" + pageState.searchText : '';

        try {
            return await axios.get(`/community/apartment?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}${searchCondition}`);
        } catch(err){
            console.log(err);
        }
    },

    //커뮤니티 - 관리비 현장 삭제
    async removeApartmentList(items){
        const list = items.map(code => {
            return axios.delete(`/community/apartment/${code.id}`);
        });

        try {
            return await axios.all(list);
        } catch(err){
            console.log(err);
        }
    },

    //커뮤니티 - 관리비 현장 등록
    async addApartmentList(pageState){
        const body = {
            site : pageState.site,
            inappcomcd : pageState.inappcomcd,
            connection : pageState.connection,
            seedKey : pageState.seedKey,
            otp : pageState.otp,
            payNumber : pageState.payNumber,
            comId : pageState.comid,
        }

        try {
            return await axios.post(`/community/apartment`, body);
        } catch(err){
            console.log(err);
        }
    },

    //커뮤니티 - 관리비 현장 수정
    async updateApartmentList(pageState){
        const body = {
            site : pageState.site,
            inappcomcd : pageState.inappcomcd,
            connection : pageState.connection,
            seedKey : pageState.seedKey,
            otp : pageState.otp,
            payNumber : pageState.payNumber,
            comId : pageState.comId,
        }

        try {
            return await axios.put(`/community/apartment`, body);
        } catch(err){
            console.log(err);
        }
    },

    // 시스템 - 버전 관리 검색
    async getVersionControlListAsync(pageState) {
        let searchCondition = "";
        searchCondition += pageState.searchCategory1 ? "&customer.code=" + pageState.searchCategory1 : '';
        searchCondition += pageState.searchCategory2 ? "&appType=" + pageState.searchCategory2 : '';
        searchCondition += pageState.searchText ? "&version=" + pageState.searchText : '';

        try {
            return await axios.get(`/system/versionControl?page=${pageState.currentPage}&sizePerPage=${pageState.rowPerPage}${searchCondition}`)
        } catch(err) {
           console.log(err);
           return err;
        }
    },

    async getVersionControlOneAsync(Id){
        try{
            return await axios.get(`/system/versionControl/${Id}`)
        } catch (err){
            console.log(err);
            return err;
        }
    },

    async addVersionControlAsync(pageState){
        try{
            return await axios.post(`/system/versionControl`,pageState);
        } catch(err){
            console.log(err);
            return err;
        }
    },

    // 시스템 - 버전 관리 리스트 삭제
    async removeVersionControlListAsync(items){
        const list = items.map(code => {
            return axios.delete(`/system/versionControl/${code.id}`);
        });

        try {
            return await axios.all(list);
        } catch(err){
            console.log(err);
        }
    },

    //시스템 - 버전 관리 개별 삭제
    async removeVersionControlAsync(id){
        try {
            return await axios.delete(`/system/versionControl/${id}`);
        } catch (err){
            console.log(err);
            return err;
        }
    },

    async updateVersionControlAsync(pageState){
        const body = {
            customer : pageState.customer,
            appType : pageState.appType,
            version : pageState.version,
            description : pageState.description,
            boolForceUpdate : pageState.boolForceUpdate,
            boolGeneralUpdate : pageState.boolGeneralUpdate,
            usable : pageState.usable,
        }

        try{
            return await axios.put(`/system/versionControl/${pageState.versionControlId}`,body);
        } catch (err){
            console.log(err);
            return err;
        }
    },

    async executeBatchControl(state){
        const body = {
            siteCode : state.site.code,
            dong : state.dong,
            modelCode : state.modelCode.code,
            property : state.property,
            value : state.value
        }
        if(state.ho !== "" )
            body.ho = state.ho
        if(state.line !== "")
            body.line = state.line

        try {
            return await axios.post(`/monitoring/batch/execute`, body)
        }catch(err){
            console.log(err);
            return err;
        }
    },

    async sendWelcomeTalkAsyncAll(items){
        const list = items.map(item => {
            return axios.post(`/notifications/alimtalk/welcome`,item);
        });

        try {
            return await axios.all(list)
        }catch(err){
            console.log(err);
        }
    },

    async sendWelcomeTalkAsync(item){

        try{
            return await axios.post(`/notifications/alimtalk/welcome`,item);
        } catch(err){
            console.log(err);
        }
    }




}


